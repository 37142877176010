import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Google } from "./icons/google.svg";
import { ReactComponent as Apple } from "./icons/apple.svg";

export default function Download() {
  const query = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const showCreateOrganization = query.get("showOrg") === "True" ? true : false;
  return (
    <div className="flex w-screen h-screen items-center justify-center bg-blue-300">
      <div className="flex flex-col flex-grow 2xl:flex-row overflow-hidden h-screen xl:h-3/5 max-w-5xl bg-white lg:rounded-lg shadow-xl">
        <img aria-hidden="true" className="object-cover h-24 2xl:h-auto max-w-2xl shadow-xl" src="/LoginPanel.png" alt="Well Being" />
        <span className="flex flex-col flex-grow 2xl:gap-y-2 p-4 2xl:justify-between justify-center gap-y-6 overflow-y-auto">
          <label className="text-2xl font-bold text-center">Download App</label>
          <span className="flex flex-col px-12 gap-y-1">
            <Apple className="w-auto h-20" />
            <Google className="w-auto h-20" />
          </span>
          <span className="flex flex-col gap-y-4 justify-between">
            <>
              <Link to={`/create_organization`} className="text-sm font-medium w-full text-center bg-Proud text-white dark:text-purple-400 hover:underline  cursor-pointer p-2 rounded" type="button">
                Create an Organization
              </Link>
              <Link to={`/login`} className="text-sm font-medium w-full text-center text-purple-600 dark:text-purple-400 hover:underline  cursor-pointer bg-white" type="button">
                Login
              </Link>
            </>
          </span>
        </span>
      </div>
    </div>
  );
}
