import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import PasswordInput from "./Helper/PasswordInput";

export default function ResetPassword() {
  const query = new URLSearchParams(window.location.search);
  document.title = "Wellm8 Password Reset Form";
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const mail = query.get("mail");
  const token = query.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  function resetPassword() {
    if (password === "") setError("Password is empty.");
    else if (password !== confirmPassword) setError("Passwords Don't Match");
    else dispatch(UserActions.resetPassword({ password: JSON.stringify({ password, mail, token }) }));
  }

  useEffect(() => setError(""), [password, confirmPassword]);
  useEffect(() => !["pending", "idle"].includes(userSelector.status.resetPassword) && setError(userSelector.messages.resetPassword), [userSelector.messages.resetPassword]);

  return (
    <div className="flex w-screen h-screen items-center justify-center bg-blue-300">
      <div className="flex flex-col flex-grow xl:flex-row overflow-y-auto h-screen xl:h-3/5 2xl:h-2/5 max-w-5xl bg-white lg:rounded-lg shadow-xl">
        <img aria-hidden="true" className="object-cover h-24 xl:h-auto xl:max-w-2xl shadow-xl" src="/LoginPanel.png" alt="Well Being" />
        <span className="flex flex-col flex-grow 2xl:gap-y-2 gap-y-6 p-4 2xl:justify-between justify-center">
          <label className="text-2xl font-bold text-center">Reset Password</label>
          <span className="flex flex-col gap-y-1">
            <span className="flex flex-col">
              <label className="font-semibold">Mail</label>
              <input type="text" value={mail} disabled className="rounded-md bg-gray-300 border-0 shadow-md" />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">New Password</label>
              <PasswordInput setValue={(e) => setPassword(e)} />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">Confirm New Password</label>
              <PasswordInput setValue={(e) => setConfirmPassword(e)} />
            </span>
          </span>
          {error !== "" ? (
            <label className="py-2 bg-red-600 text-center text-xl rounded-md font-semibold text-white">{error}</label>
          ) : (
            <input
              type="button"
              disabled={userSelector.status.resetPassword === "pending"}
              value="Reset Password"
              className="py-2 hover:bg-blue-400 cursor-pointer bg-blue-600 text-xl rounded-md font-semibold text-white"
              onClick={() => resetPassword()}
            />
          )}
        </span>
      </div>
    </div>
  );
}
