import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { UserActions } from "../../redux/UserActions";
import { API_URL, moodColors, moodValues } from "../../redux/constants";

export default function DashboardReport() {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const [data, setData] = useState({});
  const [isExporting, setIsExporting] = useState(false);

  const currentDate = new Date();

  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());

  const [monthName, setMonthName] = useState(new Date(selectedYear, selectedMonth - 1, 1).toLocaleString("default", { month: "short" }));

  useEffect(() => {
    setMonthName(new Date(selectedYear, selectedMonth - 1, 1).toLocaleString("default", { month: "short" }));
    dispatch(UserActions.dashboardReport({ organization: userState.selectedOrganization, arguments: `?mainYear=${selectedYear}&mainMonth=${selectedMonth}` }));
  }, [selectedMonth]);

  function generateReport() {
    setIsExporting(true);
    fetch(`${API_URL}v2/organizations/${userState.selectedOrganization}/reports/dashboard`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${userState.user.token}` },
      body: JSON.stringify(data),
    })
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Wellm8 General Report ${monthName}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsExporting(false);
      })
      .catch((err) => {
        return Promise.reject({ Error: "Something Went Wrong", err });
      });
  }

  useEffect(() => {
    setData(userState.dashboardReport);
  }, [userState.dashboardReport]);
  return (
    <>
      <div className="w-full max-h-screen flex justify-center bg-gray-300 overflow-hidden">
        <div className="flex flex-wrap justify-center 2xl:grid-cols-5 w-full p-4 gap-4 max-w-8xl overflow-y-auto ">
          <DateFilter className="h-64 w-full lg:max-w-xs col-span-1" selectMonth={(e) => setSelectedMonth(e)} selectYear={(e) => setSelectedYear(e)} selectedMonth={selectedMonth} selectedYear={selectedYear} />
          <CompanyOverview
            generateReport={() => generateReport()}
            isExporting={isExporting}
            className="h-64 w-full md:max-w-md col-span-1"
            activeUsers={data?.companyOverview?.activeUsers}
            totalMoods={data?.companyOverview?.moodsCount}
            thisMonthMoods={data?.companyOverview?.mainMonthMoodsCount}
            lastMonthMoods={data?.companyOverview?.referenceMonthMoodsCount}
          />
          <MoodScore className="h-64 w-full md:max-w-xs" moodScore={data?.moodScoreTrend?.length > 0 ? data?.moodScoreTrend[0]?.value : 0} />
          <MoodScoreTrendline className="h-64 w-full xl:max-w-2xl col-span-2" lineData={data?.moodScoreTrend || []} />
          <PrioritiesPieChart className="h-64 w-full md:max-w-xs xl:max-w-xs 2xl:max-w-sm col-span-2" prioritiesData={data?.prioritiesAnalytics?.mainMonthPriorities || {}} />
          <MoodHistoryBar className="h-64 w-full 2xl:max-w-6xl col-span-2" barData={data?.moodHistory?.total || []} />
          <DepartmentMoodSplit className="h-64 w-full xl:max-w-lg 2xl:max-w-6xl col-span-2" barData={data?.moodHistory?.departments || []} />
          <LocationMoodSplit className="h-64 w-full xl:max-w-lg 2xl:max-w-6xl col-span-2" barData={data?.moodHistory?.locations || []} />
        </div>
      </div>
    </>
  );
}

function DateFilter({ className, selectMonth, selectYear, selectedMonth, selectedYear }) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const currentDate = new Date().getFullYear();

  const [currentYear, setCurrentYear] = useState(selectedYear);
  const [currentMonth, setCurrentMonth] = useState(selectedMonth - 1);

  useEffect(() => {
    selectMonth(currentMonth + 1);
    selectYear(currentYear);
  }, [currentMonth]);

  return (
    <div className={"flex flex-col items-center w-full bg-white rounded-lg py-2 px-4 gap-y-2 " + className}>
      <label className="w-full text-center text-xl font-bold">Select Month</label>
      <span className="flex h-8 w-full justify-between items-center bg-gray-200 rounded-xl">
        <ChevronLeftIcon className="w-10 h-8 cursor-pointer hover:bg-gray-400 rounded-xl select-none" onClick={() => setCurrentYear(currentYear - 1)} />
        <label>{currentYear}</label>
        <ChevronRightIcon className="w-10 h-8 cursor-pointer hover:bg-gray-400 rounded-xl select-none" onClick={() => currentYear < currentDate && setCurrentYear(currentYear + 1)} />
      </span>
      <span className="flex flex-wrap gap-2 w-full items-center justify-evenly">
        {months.map((month) => (
          <label
            onClick={() => {
              setCurrentMonth(months.findIndex((m) => m === month));
            }}
            className={"w-16 py-2 text-center cursor-pointer hover:bg-gray-400 rounded-full " + (month === months[currentMonth] && currentYear === selectedYear ? "bg-Secondary" : "bg-gray-200")}
          >
            {month}
          </label>
        ))}
      </span>
    </div>
  );
}

function CompanyOverview({ generateReport, isExporting, className, activeUsers, totalMoods, thisMonthMoods, lastMonthMoods }) {
  return (
    <div className={`flex flex-col bg-white items-center rounded-lg p-4 gap-y-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">Company Overview</label>
      <span className="flex flex-col flex-grow justify-evenly">
        <TableLabels name="Active Users" value={activeUsers || 0} />
        <TableLabels name="All Time Mood Shares" value={totalMoods || 0} />
        <TableLabels name="This Months Mood Shares" value={thisMonthMoods || 0} />
        <TableLabels name="Previous Months Mood Shares" value={lastMonthMoods || 0} />
      </span>
      <button className="bg-Secondary p-2 rounded-lg px-4 font-semibold" disabled={isExporting} onClick={() => generateReport()}>
        {isExporting ? "Exporting..." : "Export Report"}
      </button>
    </div>
  );
}

function TableLabels({ name, value }) {
  return (
    <span className="flex gap-x-2 items-center">
      <label className="text-lg w-64">{name}</label>
      <label className="text-xl font-semibold">{value}</label>
    </span>
  );
}

function MoodScore({ className, moodScore }) {
  return (
    <div className={`flex flex-col w-full relative bg-white rounded-lg p-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">Mood Score</label>
      <label className="w-full text-center font-semibold">This Month</label>
      <ResponsiveRadialBar
        data={[{ id: "moodScore", data: [{ x: "MoodScore", y: moodScore, color: "#4CAF50" }] }]}
        margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
        colors={{ datum: "data.color" }}
        maxValue={100}
        valueFormat=">-.2f"
        startAngle={-135}
        endAngle={135}
        innerRadius={0.7}
        padding={0.15}
        cornerRadius={20}
        enableRadialGrid={false}
        enableLabels={false}
        radialAxisStart={false}
        circularAxisOuter={false}
        isInteractive={false}
        enableTracks={false}
        layers={["grid", "tracks", "bars", CenteredScoreMetric]}
      />
    </div>
  );
}

const CenteredScoreMetric = ({ center, bars }) => {
  let total = Math.round(bars[0].value);

  return (
    <g>
      <text x={center[0]} y={center[1]} textAnchor="middle" dominantBaseline="central" style={{ fontSize: "28px", fontWeight: 600 }}>
        {`${total}%`}
      </text>
    </g>
  );
};

function MoodScoreTrendline({ className, lineData }) {
  const [data, setData] = useState([]);

  useEffect(() => lineData.length > 0 && setData([{ id: "moodScoreTrend", color: "#4CAF50", data: lineData.map((line) => ({ x: line.name, y: Math.round(line.value) })).reverse() }]), [lineData]);
  return (
    <div className={`flex flex-col bg-white rounded-lg p-4 gap-y-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">Mood Score Trendline</label>
      <ResponsiveLine
        data={data}
        curve="monotoneX"
        margin={{ top: 10, right: 30, bottom: 30, left: 30 }}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: "0", max: "100", stacked: true, reverse: false }}
        colors={{ datum: "color" }}
        axisTop={null}
        axisRight={null}
        axisLeft={{ tickValues: [0, 25, 50, 75, 100] }}
        enableGridX={false}
        enableGridY={true}
        lineWidth={4}
        useMesh={true}
        pointSize={10}
      />
    </div>
  );
}

function MoodHistoryBar({ className, barData }) {
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    const tempKeys = [];
    const tempData = [];
    if (barData.length > 0) {
      tempKeys.push(barData[1].name);
      tempKeys.push(barData[0].name);
      Object.keys(moodValues).map(
        (moodKey) =>
          (moodKey in barData[0].value || moodKey in barData[1].value) &&
          tempData.push({
            mood: moodValues[moodKey],
            [barData[0].name]: barData[0].value[moodKey] || 0,
            [`${barData[0].name}Color`]: moodColors[moodValues[moodKey]],
            [barData[1].name]: barData[1]?.value[moodKey] || 0,
            [`${barData[1].name}Color`]: `${moodColors[moodValues[moodKey]]}CC`,
          })
      );
    }
    setData(tempData);
    setKeys(tempKeys);
  }, [barData]);

  return (
    <div className={`flex flex-col bg-white rounded-lg p-2 gap-y-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">{`Mood Breakdown (${keys.join(" - ")})`}</label>
      <ResponsiveBar
        data={data}
        keys={keys}
        groupMode="grouped"
        indexBy="mood"
        margin={{ top: 5, right: 0, bottom: 25, left: 0 }}
        padding={0.2}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        colors={({ id, data }) => String(data[`${id}Color`])}
        axisBottom={{
          renderTick: CustomMoodLabel,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#ffffff"
      />
    </div>
  );
}

const CustomMoodLabel = (tick) => {
  return (
    <g transform={`translate(${tick.x},${tick.y + 12})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: "#333",
          fontSize: 10,
        }}
      >
        {tick.value}
      </text>
    </g>
  );
};

function DepartmentMoodSplit({ className, barData }) {
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);

  // const [match, setMatch] = useState("");

  useEffect(() => {
    const tempKeys = [];
    const tempData = [];
    if (barData.length > 0) {
      Object.keys(moodValues).map((moodKey) => {
        const tempRow = {};
        Object.keys(barData[0].value).map((depKey, index) => {
          tempKeys.find((k) => k === depKey) || tempKeys.push(depKey);
          Object.assign(tempRow, {
            [depKey]: barData[0].value[depKey][moodKey] || 0,
          });
        });

        tempData.push({
          mood: moodValues[moodKey],
          ...tempRow,
        });
      });
    }
    setData(tempData);
    setKeys(tempKeys);
  }, [barData]);

  return (
    <div className={`flex flex-col bg-white rounded-lg p-2 gap-y-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">Department Moods Breakdown</label>
      <ResponsiveBar
        data={data}
        keys={keys}
        // groupMode="stacked"
        groupMode="grouped"
        indexBy="mood"
        margin={{ top: 5, right: 110, bottom: 25, left: 0 }}
        padding={0.2}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        colors={{ scheme: "paired" }}
        axisBottom={{
          renderTick: CustomMoodLabel,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#000000"
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 110,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 15,
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 15,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}

function LocationMoodSplit({ className, barData }) {
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    const tempKeys = [];
    const tempData = [];
    if (barData.length > 0) {
      Object.keys(moodValues).map((moodKey) => {
        const tempRow = {};
        Object.keys(barData[0].value).map((depKey, index) => {
          tempKeys.find((k) => k === depKey) || tempKeys.push(depKey);
          Object.assign(tempRow, {
            [depKey]: barData[0].value[depKey][moodKey] || 0,
          });
        });

        tempData.push({
          mood: moodValues[moodKey],
          ...tempRow,
        });
      });
    }
    setData(tempData);
    setKeys(tempKeys);
  }, [barData]);

  return (
    <div className={`flex flex-col bg-white rounded-lg p-2 gap-y-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">Location Moods Breakdown</label>
      <ResponsiveBar
        data={data}
        keys={keys}
        groupMode="grouped"
        indexBy="mood"
        margin={{ top: 5, right: 110, bottom: 25, left: 0 }}
        padding={0.2}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        colors={{ scheme: "paired" }}
        axisBottom={{
          renderTick: CustomMoodLabel,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#000000"
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 110,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 15,
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 15,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}

function PrioritiesTable({ className, priorityMoods }) {
  return (
    <div className={`flex flex-col bg-white rounded-lg p-2 gap-y-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">Priority Moods</label>
      <span className="flex flex-col h-full items-center justify-center">
        <TableLabels name="Priority Moods" value={priorityMoods || 0} />
      </span>
    </div>
  );
}

function PrioritiesPieChart({ className, prioritiesData }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const tempData = [];
    Object.keys(prioritiesData).map((priority) => tempData.push({ id: priority, label: priority.toLowerCase().replace(" ", "_"), value: prioritiesData[priority] }));

    setData(tempData);
  }, [prioritiesData]);

  return (
    <div className={`flex flex-col relative bg-white rounded-lg p-2 gap-y-2 ${className}`}>
      <label className="w-full text-center text-xl font-bold">Priority Moods Breakdown</label>
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={0.5}
        padAngle={2}
        cornerRadius={5}
        colors={{ scheme: "paired" }}
        enableArcLinkLabels={false}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        activeOuterRadiusOffset={15}
        layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
      />
    </div>
  );
}

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach((datum) => (total += datum.value));

  return (
    <text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="central" style={{ fontSize: "24px", fontWeight: 600 }}>
      {total}
    </text>
  );
};
