import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function ChatMessage({ message, sender, user, previousSender }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <span
      className={`flex p-1 px-3 rounded-md sm:max-w-sm lg:max-w-lg 2xl:max-w-2xl flex-col 
      ${!user?.role?.includes("User") ? "bg-gray-100 ml-auto items-end" : "bg-blue-100 mr-auto items-start"} ${message.category !== "message" && "mx-auto"}`}
      onClick={() => setExpanded(!expanded)}
    >
      {!previousSender && !sender && <label className="text-xs font-bold">{user?.anonymous && user?.role.includes("User") ? user?.userName : `${user?.firstName} ${user?.lastName}`}</label>}
      {!previousSender && sender && <label className="text-xs font-bold">You</label>}
      <label className={`text-xl p-1 break-word cursor-pointer ${message.category !== "message" && "font-semibold"}`}>{message.body}</label>
      {expanded && <label className="text-sm">{new Date(message.date.$date).toLocaleString()}</label>}
    </span>
  );
}
