import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeOrganization } from "../redux/UserReducer";
import { UserActions } from "../redux/UserActions";

export default function Organizations() {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => setOrganizations(userSelector.organizations), [userSelector.organizations]);
  useEffect(() => dispatch(UserActions.getOrganizations({})), []);

  return (
    <div className="flex flex-col w-full h-screen gap-y-4 py-4 px-6 bg-gray-100">
      {organizations.map((organization) => (
        <span className="flex items-center bg-white rounded-lg p-4 justify-between w-full">
          <label className="text-lg font-bold">{organization.name}</label>
          {userSelector.selectedOrganization !== organization.code && (
            <span className="flex gap-x-4">
              <input type="button" className="bg-green-300 text-lg font-semibold rounded-md p-2 cursor-pointer" value="Select" onClick={() => dispatch(changeOrganization(organization.code))} />
              {/* <input
                type="button"
                className="bg-red-300 text-lg font-semibold rounded-md p-2 cursor-pointer"
                value="Delete"
                onClick={() => dispatch(UserActions.deleteOrganization({ organization: organization.code }))}
              /> */}
            </span>
          )}
        </span>
      ))}

      <span className="flex items-center bg-white rounded-lg p-4 justify-between w-full">
        <label className="text-lg font-bold">No Organization</label>
        {userSelector.selectedOrganization !== "0" && (
          <span className="flex gap-x-4">
            <input type="button" className="bg-green-300 text-lg font-semibold rounded-md p-2 cursor-pointer" value="Select" onClick={() => dispatch(changeOrganization("0"))} />
          </span>
        )}
      </span>
    </div>
  );
}
