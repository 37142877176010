import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions";
import { useParams } from "react-router-dom";
import ChatInfo from "./ChatInfo";
import ChatMessages from "./ChatMessages";
import ChatInput from "./ChatInput";
import { socket } from "../../App";
import { seeMessages } from "../../redux/UserReducer";

export default function Chat({}) {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);
  const chatUser = useParams().user;
  const [chat, setChat] = useState({});

  useEffect(() => dispatch(UserActions.getConversation({ user: chatUser })), [chatUser]);
  useEffect(() => setChat(userSelector?.conversations?.find((convo) => convo.user._id.$oid === chatUser) || {}), [userSelector.conversations]);
  useEffect(() => {
    if (chat?._id?.$oid) {
      socket.emit("see_message", { conversation: chat._id.$oid, _id: userSelector.user._id.$oid });
      dispatch(seeMessages({ conversation: chat._id.$oid, _id: userSelector.user._id.$oid }));
    }
  }, [chat?.messages?.length]);

  useEffect(() => {
    if (chat?._id?.$oid && chat.messages?.length > 0) {
      if (!chat.supports.find((support) => support._id.$oid === userSelector.user._id.$oid) || chat.muted.includes(userSelector.user._id.$oid)) {
        socket.emit("join_room", { _id: String(userSelector.user._id.$oid), room: String(chat._id.$oid) });
        return () => socket.emit("leave_room", { _id: String(userSelector.user._id.$oid), room: String(chat._id.$oid) });
      } else {
        socket.emit("leave_room", { _id: String(userSelector.user._id.$oid), room: String(chat._id.$oid) });
      }
    }
  }, [chat?.supports]);

  return (
    <div className="flex flex-col flex-grow">
      <ChatInfo chat={chat} />
      <ChatMessages chat={chat} messages={chat?.messages} />
      <ChatInput conversation={chat} />
    </div>
  );
}
