import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import { useParams, Link } from "react-router-dom";
import { MapIcon, UserGroupIcon, CashIcon, UserCircleIcon, CogIcon } from "@heroicons/react/solid";

import AdminsAndSupportsTab from "./Settings/AdminsAndSupportsTab";
import DepartmentsTab from "./Settings/DepartmentsTab";
import LocationsTab from "./Settings/LocationsTab";
import SubscriptionAndBillingTab from "./Settings/SubscriptionAndBillingTab";
import OrganizationSettingsTab from "./Settings/OrganizationSettingsTab";

export default function OrganizationSettings() {
  const tab = useParams().tab;

  const tabs = [
    { location: "departments", label: "Departments", icon: UserGroupIcon, iconClass: "text-blue-400" },
    { location: "locations", label: "Locations", icon: MapIcon, iconClass: "text-green-400" },
    // { location: "subscriptions", label: "Subscription & Billing", icon: CashIcon, iconClass: "text-purple-400" },
    { location: "admins", label: "Admins & Supports", icon: UserCircleIcon, iconClass: "text-gray-400" },
    { location: "settings", label: "Organisation Settings", icon: CogIcon, iconClass: "text-gray-400" },
  ];

  return (
    <div className="flex flex-col w-full h-screen bg-gray-100 gap-y-8 p-6 overflow-hidden">
      <div className="grid grid-cols-4 gap-x-4 h-16 lg:h-60">
        {tabs.map((category) => (
          <CategoryTab category={category} />
        ))}
      </div>
      {tab === "departments" && <DepartmentsTab />}
      {tab === "locations" && <LocationsTab />}
      {tab === "subscriptions" && <SubscriptionAndBillingTab />}
      {tab === "admins" && <AdminsAndSupportsTab />}
      {tab === "settings" && <OrganizationSettingsTab />}
    </div>
  );
}

function CategoryTab({ category }) {
  return (
    <Link to={category.location} className="flex flex-col bg-white rounded-lg p-4 cursor-pointer">
      <category.icon className={`h-12 lg:h-44 pointer-events-none ${category.iconClass}`} />
      <label className="w-full hidden lg:block break-word text-center text-2xl font-semibold pointer-events-none">{category.label}</label>
    </Link>
  );
}
