import React from "react";

export default function UserActivity({ active }) {
  return (
    <span className="bg-gray-400 w-32 h-5 rounded relative ">
      {active === "No Activity" && <span className="absolute h-5 rounded-l bg-red-400 w-0" />}
      {active === "Low Activity" && <span className="absolute h-5 rounded-l bg-Sad w-8" />}
      {active === "Medium Activity" && <span className="absolute h-5 rounded-l bg-yellow-400 w-16" />}
      {active === "High Activity" && <span className="absolute h-5 rounded-l bg-green-400 w-24" />}
      {active === "Daily Activity" && <span className="absolute h-5 rounded-l bg-green-600 w-32" />}
    </span>
  );
}
