import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserList from "./UserList.js";
import Chat from "./Chat.js";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions.js";

export default function Conversations() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const chatUser = useParams().user || "";
  const [expanded, setExpanded] = useState(false);

  useEffect(() => (chatUser === "" ? dispatch(UserActions.getConversations({ user: user.user._id.$oid })) : dispatch(UserActions.getConversation({ user: chatUser }))), []);
  return (
    <>
      <div className="flex overflow-hidden w-full flex-grow">
        {chatUser != "" && <Chat selected={chatUser} expanded={expanded} />}
        <UserList _handleExpanded={(e) => setExpanded(e)} />
      </div>
    </>
  );
}
