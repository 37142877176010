import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/solid";
import { useCurrentWidth } from "./Helper/WidthHook";
import routes from "../SideBarRoutes";
import SidebarContent from "./SideBar/SidebarContent";
import { ReactComponent as Logo } from "./icons/logo.svg";

export default function SideBar() {
  const [expanded, setExpanded] = useState(false);
  const matches = useCurrentWidth();

  return (
    <>
      {matches ? (
        <div className="flex flex-col flex-grow z-10 h-screen overflow-y-auto w-72 bg-white dark:bg-gray-800 justify-between p-4 max-w-xs shadow-lg">
          <SidebarContent />
        </div>
      ) : (
        <>
          <div className="z-10 items-center w-screen h-14 justify-center bg-gray-200 flex-col flex dark:bg-gray-800">
            <Logo className="w-48 h-14 p-2" />
            <MenuIcon className="absolute left-0 rounded-md h-10 w-10 p-1 ml-1 hover:bg-gray-300" onClick={() => setExpanded(!expanded)} />
          </div>
          <MobileSidebar isSidebarOpen={expanded} closeSidebar={() => setExpanded(!expanded)} />
        </>
      )}
    </>
  );
}

function MobileSidebar({ isSidebarOpen, closeSidebar }) {
  return (
    <Transition show={isSidebarOpen}>
      <Transition
        enter="transition ease-in-out duration-150"
        enterFrom="opacity-0 transform -translate-x-20"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 transform -translate-x-20"
      >
        <aside className="fixed left-0 p-2 flex flex-col justify-between gap-y-6 flex-grow w-screen z-50 bg-white">
          <SidebarContent closeSidebar={closeSidebar} />
        </aside>
      </Transition>
    </Transition>
  );
}
