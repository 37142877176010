import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import { Dialog, Transition } from "@headlessui/react";
import MultiSelectListBox from "./Helper/MultiSelectListBox";
import { ArrowsExpandIcon, ChevronDownIcon, ChevronUpIcon, XIcon } from "@heroicons/react/outline";
import { ResponsivePie } from "@nivo/pie";
import ConfirmationDialog from "./ConfirmationDialog";

export default function Notifications() {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [expandedNotification, setExpandedNotification] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => setNotifications([...userSelector.notifications].reverse()), [userSelector.notifications]);
  useEffect(() => dispatch(UserActions.getNotifications({ code: userSelector.selectedOrganization })), []);
  useEffect(() => setLoading(userSelector.status.notifications === "pending"), [userSelector.status.notifications]);
  useEffect(() => setCreateLoading(userSelector.status.createNotification === "pending"), [userSelector.status.createNotification]);

  return (
    <>
      <div className="flex flex-col gap-y-4 p-4 w-full bg-gray-100">
        <input
          className="text-lg font-semibold py-2 px-8 mx-auto bg-green-300 rounded-md cursor-pointer"
          type="button"
          disabled={createLoading}
          value={createLoading ? "Creating..." : "Create Poll"}
          onClick={() => setIsCreateModalOpen(true)}
        />
        <div className="flex flex-wrap justify-center gap-4 overflow-y-auto overflow-x-hidden">
          {notifications.length === 0 ? (
            <label className="w-full text-center text-xl font-semibold">{loading ? "Loading..." : "No Polls"}</label>
          ) : (
            notifications.map((notification) => <Notification notification={notification} />)
          )}
        </div>
      </div>
      <CreateNotificationCard isOpen={isCreateModalOpen} setIsOpen={(e) => setIsCreateModalOpen(e)} />
    </>
  );
}

function Notification({ notification }) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [sendModalExpanded, setSendModalExpanded] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <span className="flex w-80 max-w-sm  flex-col bg-white cursor-pointer justify-between" onClick={() => setExpanded(true)}>
      <span className="flex justify-center items-center bg-gray-300 p-4 rounded-t-md h-16">
        <label className="font-semibold text-center break-words">{notification.body}</label>
      </span>
      <span className="h-52 flex flex-col items-center justify-center">
        {notification.replies.length > 0 ? (
          <ResponsivePie
            data={[
              { id: "positive", label: "Positive", value: notification.replies.filter((reply) => reply.response === "positive").length, color: "#66BB6A" },
              { id: "negative", label: "Negative", value: notification.replies.filter((reply) => reply.response === "negative").length, color: "#DF605D" },
              { id: "neutral", label: "Neutral", value: notification.replies.filter((reply) => reply.response === "neutral").length, color: "#839098" },
            ]}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            innerRadius={0.5}
            padAngle={1}
            cornerRadius={3}
            colors={{ datum: "data.color" }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            activeOuterRadiusOffset={10}
            layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CustomLayerComponent({ reached: notification.reached, responded: notification.replies.length })]}
          />
        ) : (
          <label className="flex font-semibold text-xl text-center">{`No Replies / ${notification.reached}`}</label>
        )}
      </span>
      <span className="flex items-center font-medium gap-1">
        <span className="bg-green-300 p-2 rounded-bl-md cursor-pointer">
          <label className="pointer-events-none">Expand</label>
        </span>
        {notification.status !== "closed" && new Date(notification?.expirationDate?.$date) > new Date() && notification?.replies?.length < notification?.reached ? (
          <input type="button" className="flex-grow bg-green-300 rounded-br-md py-2 cursor-pointer" value="Send Reminder" onClick={() => setSendModalExpanded(true)} />
        ) : (
          <span className="flex items-center py-2 justify-center w-full bg-gray-300 rounded-br-md">
            <label className="">Poll Closed</label>
          </span>
        )}
      </span>
      {sendModalExpanded && (
        <ConfirmationDialog
          expanded={sendModalExpanded}
          confirmClass="bg-green-500 hover:bg-green-300"
          setExpanded={(e) => setSendModalExpanded(e)}
          confirm={() => dispatch(UserActions.remindNotification({ code: userSelector.selectedOrganization, notification: notification._id.$oid }))}
          title="Confirm Poll Reminder"
          description="You will send a reminder to vote on poll."
        />
      )}
      {expanded && <ExpandedNotification setExpanded={(e) => setExpanded(e)} notification={notification} />}
    </span>
  );
}

function ExpandedNotification({ notification, setExpanded }) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [removeModalExpanded, setRemoveModalExpanded] = useState(false);
  const [sendModalExpanded, setSendModalExpanded] = useState(false);

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 h-screen w-screen" onClose={() => setExpanded(false)}>
        <div className="min-h-screen  text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-20" leave="ease-in duration-200" leaveFrom="opacity-30" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <div className="flex w-screen h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex flex-col justify-between gap-y-2 w-full max-w-3xl h-auto p-6 transition-all transform bg-white shadow-xl rounded-2xl">
                <span className="flex h-8 items-center justify-between">
                  <XIcon className="h-8 w-8 cursor-pointer" onClick={() => setExpanded(false)} />
                  <label className="text-xl font-semibold">{notification.body}</label>
                  <span className="h-8 w-8"></span>
                </span>
                <span className="flex w-full ">
                  <span className="flex flex-grow justify-evenly flex-col gap-y-4">
                    <FieldLabel name={"Departments"} value={notification.departments.length > 0 ? notification.departments.join(", ") : "All Departments"} />
                    <FieldLabel name={"Locations"} value={notification.locations.length > 0 ? notification.locations.join(", ") : "All Locations"} />
                    <FieldLabel
                      name={"Expiration Date"}
                      value={new Date(notification.expirationDate.$date).toLocaleString("en-GB", { hour: "2-digit", minute: "2-digit", day: "2-digit", month: "short", year: "2-digit" })}
                    />
                  </span>

                  <span className="h-52 flex max-w-sm flex-grow flex-col items-center justify-center">
                    {notification.replies.length > 0 ? (
                      <ResponsivePie
                        data={[
                          { id: "positive", label: "Positive", value: notification.replies.filter((reply) => reply.response === "positive").length, color: "#66BB6A" },
                          { id: "negative", label: "Negative", value: notification.replies.filter((reply) => reply.response === "negative").length, color: "#DF605D" },
                          { id: "neutral", label: "Neutral", value: notification.replies.filter((reply) => reply.response === "neutral").length, color: "#839098" },
                        ]}
                        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                        innerRadius={0.5}
                        padAngle={1}
                        cornerRadius={3}
                        colors={{ datum: "data.color" }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
                        activeOuterRadiusOffset={10}
                        layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CustomLayerComponent({ reached: notification.reached, responded: notification.replies.length })]}
                      />
                    ) : (
                      <label className="flex font-semibold text-xl text-center">{`No Replies / ${notification.reached}`}</label>
                    )}
                  </span>
                </span>
                {notification.replies.length > 0 ? (
                  notification.anonymous ? (
                    <span className="flex w-full h-full items-center justify-center">
                      <label className="font-semibold text-xl">Anonymous Poll</label>
                    </span>
                  ) : (
                    <div className="flex flex-col max-h-56 bg-gray-50 p-2 col-span-3 overflow-y-auto gap-y-2">
                      {notification.replies.map((reply) => (
                        <NotificationReplyBar reply={reply} />
                      ))}
                    </div>
                  )
                ) : null}

                <span className="flex flex-grow gap-x-2">
                  {notification.status !== "closed" && new Date(notification?.expirationDate?.$date) > new Date() && notification?.replies?.length < notification?.reached && (
                    <>
                      <input type="button" className="font-medium flex-grow bg-green-300 rounded-md py-2 cursor-pointer" value="Send Poll Reminder" onClick={() => setSendModalExpanded(true)} />
                      {sendModalExpanded && (
                        <ConfirmationDialog
                          expanded={sendModalExpanded}
                          setExpanded={(e) => setSendModalExpanded(e)}
                          confirmClass="bg-green-500 hover:bg-green-300"
                          confirm={() => dispatch(UserActions.remindNotification({ code: userSelector.selectedOrganization, notification: notification._id.$oid }))}
                          title="Confirm Poll Reminder"
                          description="You will send a reminder to vote on poll."
                        />
                      )}
                      <input type="button" className="font-medium flex-grow bg-red-300 rounded-md py-2 cursor-pointer" value="Close Poll" onClick={() => setRemoveModalExpanded(true)} />
                      {removeModalExpanded && (
                        <ConfirmationDialog
                          expanded={removeModalExpanded}
                          setExpanded={(e) => setRemoveModalExpanded(e)}
                          confirm={() => dispatch(UserActions.closeNotification({ code: userSelector.selectedOrganization, notification: notification._id.$oid }))}
                          title="Confirm Poll Close"
                          description="Your poll will be closed."
                        />
                      )}
                    </>
                  )}
                  {["SuperAdmin"].includes(userSelector.user.role) && (
                    <input
                      type="button"
                      className="font-medium flex-grow bg-red-00 rounded-md py-2 cursor-pointer"
                      value="Delete"
                      onClick={() => dispatch(UserActions.deleteNotification({ notification: notification._id.$oid }))}
                    />
                  )}
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

const CustomLayerComponent =
  ({ reached, responded }) =>
  ({ centerX, centerY }) => {
    return (
      <text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="central" style={{ fontSize: "16px", fontWeight: "600" }}>
        {`${responded}/${reached}`}
      </text>
    );
  };

function ExpandedNotificationCard({ notification, setExpandedNotification }) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [removeModalExpanded, setRemoveModalExpanded] = useState(false);
  const [sendModalExpanded, setSendModalExpanded] = useState(false);

  return (
    <div className="grid lg:grid-cols-4 items-center grid-cols-1 gap-y-4 bg-white py-2 px-2 rounded-md">
      <FieldLabel name="Notification Body:" value={notification.body} />
      <FieldLabel name="Responses:" value={`${notification.replies?.length} / ${notification.reached}`} />
      <FieldLabel name="Expiration Date:" value={new Date(notification?.expirationDate?.$date).toString()} />
      <ChevronUpIcon className="col-span-1 w-full h-8 text-gray-400" onClick={() => setExpandedNotification("")} />
      {notification.replies?.length > 0 && (
        <span className="w-full lg:col-span-4 col-span-1 grid lg:grid-cols-4 grid-cols-1">
          <span className="h-64">
            <ResponsivePie
              data={[
                { id: "positive", label: "Positive", value: notification.replies.filter((reply) => reply.response === "positive").length, color: "#66BB6A" },
                { id: "negative", label: "Negative", value: notification.replies.filter((reply) => reply.response === "negative").length, color: "#DF605D" },
                { id: "neutral", label: "Neutral", value: notification.replies.filter((reply) => reply.response === "neutral").length, color: "#839098" },
              ]}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              innerRadius={0.5}
              padAngle={2}
              cornerRadius={5}
              colors={{ datum: "data.color" }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              activeOuterRadiusOffset={15}
            />
          </span>
          {notification.anonymous ? (
            <span className="flex w-full h-full items-center justify-center">
              <label className="font-semibold text-xl">Anonymous Poll</label>
            </span>
          ) : (
            <div className="flex flex-col max-h-72 p-2 col-span-3 overflow-y-auto gap-y-2">
              {notification.replies.map((reply) => (
                <NotificationReplyBar reply={reply} />
              ))}
            </div>
          )}
        </span>
      )}
      {notification.status !== "closed" && new Date(notification?.expirationDate?.$date) > new Date() && notification?.replies?.length < notification?.reached && (
        <span className="flex col-span-4 flex-grow gap-x-2">
          <input type="button" className="font-medium flex-grow bg-green-300 rounded-md py-2 cursor-pointer" value="Send Poll Reminder" onClick={() => setSendModalExpanded(true)} />
          {sendModalExpanded && (
            <ConfirmationDialog
              expanded={sendModalExpanded}
              confirmClass="bg-green-500 hover:bg-green-300"
              setExpanded={(e) => setSendModalExpanded(e)}
              confirm={() => dispatch(UserActions.remindNotification({ code: userSelector.selectedOrganization, notification: notification._id.$oid }))}
              title="Confirm Poll Reminder"
              description="You will send a reminder to vote on poll."
            />
          )}
          <input type="button" className="font-medium flex-grow bg-red-300 rounded-md py-2 cursor-pointer" value="Close Poll" onClick={() => setRemoveModalExpanded(true)} />
          {removeModalExpanded && (
            <ConfirmationDialog
              expanded={removeModalExpanded}
              setExpanded={(e) => setRemoveModalExpanded(e)}
              confirm={() => dispatch(UserActions.closeNotification({ code: userSelector.selectedOrganization, notification: notification._id.$oid }))}
              title="Confirm Poll Removal"
              description="Your poll will be deleted."
            />
          )}
        </span>
      )}
    </div>
  );
}

function NotificationCard({ notification, setExpandedNotification }) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [removeModalExpanded, setRemoveModalExpanded] = useState(false);
  const [sendModalExpanded, setSendModalExpanded] = useState(false);

  return (
    <div className="grid lg:grid-cols-4 grid-cols-1 gap-y-4 items-center bg-white py-2 px-4 rounded-md">
      <FieldLabel name="Notification Body:" value={notification.body} />
      <FieldLabel name="Responses:" value={`${notification.replies?.length} / ${notification.reached}`} />
      <FieldLabel name="Expiration Date:" value={new Date(notification?.expirationDate?.$date).toString()} />

      <span className="flex flex-grow gap-x-2">
        {notification.status !== "closed" && new Date(notification?.expirationDate?.$date) > new Date() && notification?.replies?.length < notification?.reached && (
          <>
            <input type="button" className="font-medium flex-grow bg-green-300 rounded-md py-2 cursor-pointer" value="Send Poll Reminder" onClick={() => setSendModalExpanded(true)} />
            {sendModalExpanded && (
              <ConfirmationDialog
                expanded={sendModalExpanded}
                setExpanded={(e) => setSendModalExpanded(e)}
                confirmClass="bg-green-500 hover:bg-green-300"
                confirm={() => dispatch(UserActions.remindNotification({ code: userSelector.selectedOrganization, notification: notification._id.$oid }))}
                title="Confirm Poll Reminder"
                description="You will send a reminder to vote on poll."
              />
            )}
            <input type="button" className="font-medium flex-grow bg-red-300 rounded-md py-2 cursor-pointer" value="Close Poll" onClick={() => setRemoveModalExpanded(true)} />
            {removeModalExpanded && (
              <ConfirmationDialog
                expanded={removeModalExpanded}
                setExpanded={(e) => setRemoveModalExpanded(e)}
                confirm={() => dispatch(UserActions.closeNotification({ code: userSelector.selectedOrganization, notification: notification._id.$oid }))}
                title="Confirm Poll Close"
                description="Your poll will be closed."
              />
            )}
          </>
        )}
        {["SuperAdmin"].includes(userSelector.user.role) && (
          <input
            type="button"
            className="font-medium flex-grow bg-red-00 rounded-md py-2 cursor-pointer"
            value="Delete"
            onClick={() => dispatch(UserActions.deleteNotification({ notification: notification._id.$oid }))}
          />
        )}
      </span>
      {notification.replies?.length > 0 && <ChevronDownIcon className="col-span-4 w-full h-8 text-gray-400" onClick={() => setExpandedNotification(notification._id.$oid)} />}
    </div>
  );
}

function NotificationReplyBar({ reply }) {
  return (
    <label className={`bg-white border-gray-500 rounded-r-md p-2 border-l-4 ${reply.response === "positive" && "border-green-500"} ${reply.response === "negative" && "border-red-500"}`}>
      {reply.user.anonymous ? reply.user.userName : `${reply.user.firstName} ${reply.user.lastName}`}
    </label>
  );
}

function FieldLabel({ name, value }) {
  return (
    <span className="flex flex-col">
      <label className="text-xs text-left font-light">{name}</label>
      <label className="px-2 text-left font-semibold ">{value}</label>
    </span>
  );
}

function CreateNotificationCard(props) {
  let newDate = new Date();
  newDate.setDate(newDate.getDate() + 1);
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [body, setBody] = useState("");
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [anonymous, setAnonymous] = useState(false);
  const [userActivity, setActivity] = useState([]);
  const [date, setDate] = useState(newDate.toLocaleDateString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit" }));
  const [time, setTime] = useState(newDate.toLocaleTimeString("en-CA", { hour: "numeric", minute: "2-digit", hour12: false }));
  const [error, setError] = useState("");

  function createCustomNotification() {
    const expirationDate = new Date(new Date(`${date} ${time}`).toUTCString());
    if (body === "") setError("body");
    else if (date === "") setError("date");
    else if (time === "") setError("hour");
    else {
      dispatch(UserActions.createNotification({ code: userSelector.selectedOrganization, notification: JSON.stringify({ body, departments, locations, userActivity, anonymous, expirationDate }) }));
      props.setIsOpen(false);
    }
  }

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 h-screen w-screen" onClose={() => props.setIsOpen(false)}>
        <div className="min-h-screen  text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-20" leave="ease-in duration-200" leaveFrom="opacity-30" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <div className="flex w-screen h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-3xl h-auto p-6 transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="flex items-center text-lg w-full justify-center text-center font-medium leading-6 text-gray-900">
                  <XIcon className="fixed left-4 h-8 w-8" onClick={() => props.setIsOpen(false)} />
                  Create a Poll
                </Dialog.Title>
                <div className="flex flex-col gap-y-1 mt-2">
                  <p className="text-sm text-gray-500">Your poll will be sent out to everyone targeted by the filters combined.</p>
                  <hr className="my-2" />
                  <div className="grid grid-cols-3 gap-y-2 items-center">
                    <label className="w-full text-left">Body:</label>
                    <input type="text" className={`col-span-2 border-1 rounded-md bg-gray-100 ${error === "body" ? "border-red-500" : "border-gray-300"}`} onChange={(e) => setBody(e.target.value)} />
                    <label className="w-full text-left">Departments:</label>
                    <MultiSelectListBox className="col-span-2 border-gray-200" handleSelected={(e) => setDepartments(e)} options={userSelector.organization.departments} />
                    <label className="w-full text-left">Locations:</label>
                    <MultiSelectListBox className="col-span-2 border-gray-200" handleSelected={(e) => setLocations(e)} options={userSelector.organization.locations} />
                    <label className="w-full text-left">Activity:</label>
                    <MultiSelectListBox className="col-span-2 border-gray-200" handleSelected={(e) => setActivity(e)} options={["No Activity", "Low", "Medium", "High", "Daily"]} />
                    <label className="w-full text-left">Anonymous:</label>
                    <input type="checkbox" onChange={(e) => setAnonymous(e.target.checked)} className="col-span-2 border-1 border-gray-300 text-green-500 rounded-md bg-gray-100" />
                  </div>
                  <label className="w-full text-left">Expiry Date:</label>
                  <span className="flex gap-x-1 w-full">
                    <input type="time" value={time} className={`rounded flex-grow cursor-pointer ${error === "hour" ? "border-red-500" : "border-gray-300"}`} onChange={(e) => setTime(e.target.value)} />
                    <input type="date" value={date} className={`rounded flex-grow cursor-pointer ${error === "date" ? "border-red-500" : "border-gray-300"}`} onChange={(e) => setDate(e.target.value)} />
                  </span>
                </div>

                <div className="mt-4">
                  <input
                    type="button"
                    className="inline-flex justify-center px-4 py-2 cursor-pointer font-medium text-black bg-green-300 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => createCustomNotification()}
                    value="Create Notification"
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
