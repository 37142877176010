import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";

export default function ConfirmationDialog({ expanded, setExpanded, title, description, confirm, confirmClass }) {
  return (
    <Transition appear show={expanded} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 h-screen w-screen" onClose={() => setExpanded(false)}>
        <div className="min-h-screen  text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-20" leave="ease-in duration-200" leaveFrom="opacity-30" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <div className="flex w-screen h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full flex flex-col gap-y-4 max-w-xl h-auto p-6 transition-all transform bg-white shadow-xl rounded-2xl">
                <label className="text-xl font-semibold">{title}</label>
                <p className="text-sm text-gray-500">{description}</p>
                <div className="flex flex-row pt-8 justify-evenly w-full">
                  <input type="button" value="Cancel" className="px-4 py-2 font-medium text-white bg-blue-600 hover:bg-blue-300 rounded cursor-pointer" onClick={() => setExpanded(false)} />
                  <input
                    type="button"
                    value="Confirm"
                    className={`px-4 py-2 font-medium text-white bg-red-600 hover:bg-red-300 rounded cursor-pointer ${confirmClass}`}
                    onClick={() => {
                      confirm();
                      setExpanded(false);
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
