import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import { Dialog, Transition } from "@headlessui/react";
import MultiSelectListBox from "./Helper/MultiSelectListBox";
import { XIcon } from "@heroicons/react/solid";
import { weekDays } from "../redux/constants";
import ListBox from "./Helper/ListBox";
import ConfirmationDialog from "./ConfirmationDialog";

export default function Reminders() {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => setReminders([...userSelector.reminders].reverse()), [userSelector.reminders]);
  useEffect(() => dispatch(UserActions.getReminders({ code: userSelector.selectedOrganization })), []);
  useEffect(() => setLoading(userSelector.status.getReminders === "pending"), [userSelector.status.getReminders]);
  useEffect(() => setCreateLoading(userSelector.status.createReminder === "pending"), [userSelector.status.createReminder]);

  return (
    <>
      <div className="flex flex-col gap-y-4 p-4 w-full bg-gray-100">
        <input
          className="text-lg font-semibold py-2 px-8 mx-auto bg-green-300 rounded-md cursor-pointer"
          type="button"
          disabled={createLoading}
          value={createLoading ? "Creating..." : "Create Reminder"}
          onClick={() => setIsCreateModalOpen(true)}
        />
        {reminders.length === 0 ? <label className="w-full text-center text-xl font-semibold">{loading ? "Loading..." : "No Reminders"}</label> : reminders.map((reminder) => <Reminder reminder={reminder} />)}
      </div>
      <CreateReminderCard isOpen={isCreateModalOpen} setIsOpen={(e) => setIsCreateModalOpen(e)} />
    </>
  );
}

function Reminder({ reminder }) {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [removeModalExpanded, setRemoveModalExpanded] = useState(false);

  return (
    <div className="grid lg:grid-cols-5 grid-cols-1 gap-y-4 bg-white py-2 px-4 rounded-md">
      <span className="flex flex-col">
        <label className="text-xs font-light">Departments:</label>
        <label className="px-2 font-semibold ">{reminder.departments.length === 0 ? "All" : reminder.departments.join(", ")}</label>
      </span>
      <span className="flex flex-col">
        <label className="text-xs font-light">Locations:</label>
        <label className="px-2 font-semibold ">{reminder.locations.length === 0 ? "All" : reminder.locations.join(", ")}</label>
      </span>
      <span className="flex flex-col">
        <label className="text-xs font-light">Time:</label>
        <TimeLabel hour={reminder.hour} minute={reminder.minute} value={reminder.time} />
      </span>
      <span className="flex flex-col">
        <label className="text-xs font-light">Days:</label>
        <label className="px-2 font-semibold ">{reminder.days.length === 0 ? "Daily" : reminder.days.join(", ")}</label>
      </span>
      <XIcon className="w-8 h-8 m-auto text-red-600 hover:text-red-300 cursor-pointer" onClick={() => setRemoveModalExpanded(true)} />

      {removeModalExpanded && (
        <ConfirmationDialog
          expanded={removeModalExpanded}
          setExpanded={(e) => setRemoveModalExpanded(e)}
          confirm={() => dispatch(UserActions.removeReminder({ code: userSelector.selectedOrganization, reminder: reminder._id.$oid }))}
          title="Confirm Reminder Deletion"
          description="Your reminder will be deleted."
        />
      )}
    </div>
  );
}

function TimeLabel({ hour, minute }) {
  let now = new Date();
  now.setHours(Math.floor(parseInt(hour) - now.getTimezoneOffset() / 60));
  now.setMinutes(parseInt(minute) - (now.getTimezoneOffset() % 60));
  return <label className="px-2 font-semibold">{now.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })}</label>;
}

function CreateReminderCard(props) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [days, setDays] = useState([]);
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");

  function createCustomNotification() {
    const date = new Date();

    const d = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), parseInt(hour), parseInt(minute));
    const timeString = `${String(d.getUTCHours()).padStart(2, 0)}:${String(d.getUTCMinutes()).padStart(2, 0)}`;

    dispatch(UserActions.createReminder({ code: userSelector.selectedOrganization, reminder: JSON.stringify({ departments, locations, days, hour: timeString.split(":")[0], minute: timeString.split(":")[1] }) }));
    props.setIsOpen(false);
  }
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 h-screen w-screen" onClose={() => props.setIsOpen(false)}>
        <div className="min-h-screen  text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-20" leave="ease-in duration-200" leaveFrom="opacity-30" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <div className="flex w-screen h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-3xl h-auto p-6 transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="flex items-center text-lg w-full justify-center text-center font-medium leading-6 text-gray-900">
                  <XIcon className="fixed left-4 h-8 w-8" onClick={() => props.setIsOpen(false)} />
                  Create a Reminder
                </Dialog.Title>
                <div className="flex flex-col gap-y-1 mt-2">
                  <p className="text-sm text-gray-500">Your reminder will be sent out to everyone targeted by the filters combined.</p>
                  <hr className="my-2" />
                  <div className="grid grid-cols-3 gap-y-2 items-center">
                    <label className="w-full text-left">Departments:</label>
                    <MultiSelectListBox className="col-span-2 border-gray-200" options={userSelector.organization.departments} handleSelected={(e) => setDepartments(e)} />
                    <label className="w-full text-left">Locations:</label>
                    <MultiSelectListBox className="col-span-2 border-gray-200" handleSelected={(e) => setLocations(e)} options={userSelector.organization.locations} />
                    <label className="flex-grow text-left">Reminder Days:</label>
                    <MultiSelectListBox className="col-span-2 border-gray-200" handleSelected={(e) => setDays(e)} options={weekDays} />
                    <label className="flex-grow text-left">Reminder Hours:</label>
                    <ListBox
                      listClassName="col-span-2"
                      options={["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]}
                      handleSelection={(e) => setHour(e)}
                    />
                    <label className="flex-grow text-left">Reminder Minutes:</label>
                    <ListBox listClassName="col-span-2" options={["00", "15", "30", "45"]} handleSelection={(e) => setMinute(e)} />
                  </div>
                </div>

                <div className="flex flex-col mt-4 gap-y-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 mx-auto font-medium text-black bg-green-300 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => createCustomNotification()}
                  >
                    Create Reminder!
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
