import React, { useEffect, useState } from "react";
import PasswordInput from "./Helper/PasswordInput";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";

export default function CreateAccount() {
  const history = useHistory();

  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [registerClicked, setRegisterClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassord] = useState("");
  const [error, setError] = useState("Register");

  function createAccount() {
    setRegisterClicked(true);
    if (password === "") setError("Password is empty.");
    else if (mail === "") setError("Mail is empty.");
    else if (!mail.includes("@") || mail.split("@")[0].length === 0 || mail.split("@")[1].length === 0) setError("Invalid Mail.");
    else if (password !== confirmPassword) setError("Passwords don't match.");
    else dispatch(UserActions.register({ user: JSON.stringify({ password, mail, userName, firstName, lastName, mode: "web" }) }));
  }

  useEffect(() => registerClicked && userSelector.status.register === "fulfilled" && history.push("/download"), [userSelector.status.register]);
  useEffect(() => setError("Register"), [password, mail]);
  useEffect(() => setError(userSelector.messages.register), [userSelector.messages.register]);

  return (
    <div className="flex w-screen h-screen items-center justify-center bg-blue-300">
      <div className="flex flex-col flex-grow 2xl:flex-row overflow-hidden h-screen xl:h-3/5 max-w-5xl bg-white lg:rounded-lg shadow-xl">
        <img aria-hidden="true" className="object-cover h-24 2xl:h-auto max-w-2xl shadow-xl" src="/LoginPanel.png" alt="Well Being" />
        <span className="flex flex-col flex-grow 2xl:gap-y-2 p-4 2xl:justify-between justify-center gap-y-6 overflow-y-auto">
          <label className="text-2xl font-bold text-center">Create Account</label>
          <span className="flex flex-col gap-y-1">
            <span className="flex flex-col">
              <label className="font-semibold">User Name</label>
              <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">First Name</label>
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">Last Name</label>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">Mail</label>
              <input type="email" value={mail} onChange={(e) => setMail(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">Password</label>
              <PasswordInput setValue={(e) => setPassword(e)} />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">Confirm Password</label>
              <PasswordInput setValue={(e) => setConfirmPassord(e)} />
            </span>
            <Link to={`/login?mail=${mail}`} className="text-sm font-medium w-full text-right text-purple-600 dark:text-purple-400 hover:underline  cursor-pointer bg-white" type="button">
              Already have an account?
            </Link>
          </span>
          <input
            type="button"
            disabled={error !== "Register"}
            value={error}
            className={`py-2 text-xl rounded-md font-semibold text-white ${
              userSelector.status.register === "rejected" && error !== "Register" ? "bg-red-600 hover:bg-red-600" : "hover:bg-blue-400 cursor-pointer bg-blue-600"
            }`}
            onClick={() => createAccount()}
          />
        </span>
      </div>
    </div>
  );
}
