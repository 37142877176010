import React, { Fragment, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import { repeatSetup } from "../redux/UserReducer";
import { ChevronLeftIcon, PlusIcon, XIcon } from "@heroicons/react/solid";
import readXlsxFile from "read-excel-file";
import { ReactComponent as Logo } from "./icons/logo_small.svg";

export default function SetupOrganization({ showSetup }) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState(true);
  const [step, setStep] = useState(0);

  function skipSetup() {
    updateOrganization({ status: "active" });
  }

  function updateOrganization(data) {
    console.log({ code: userState.selectedOrganization, data: JSON.stringify(data) });
    dispatch(UserActions.updateOrganization({ code: userState.selectedOrganization, data: JSON.stringify(data) }));
    if (step < 3) setStep(step + 1);
    else setExpanded(false);
  }

  return (
    <Transition appear show={expanded} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 h-screen w-screen" onClose={() => console.log("")}>
        <div className="min-h-screen  text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-20" leave="ease-in duration-200" leaveFrom="opacity-30" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <div className="flex w-screen h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative flex w-full max-w-4xl xl:h-2/3 h-screen p-4 transition-all transform bg-white shadow-xl lg:rounded-xl">
                <img className="absolute inset-0 w-full h-full object-cover opacity-30" src="/setup_emoji.png" />
                {/* <Setup className="absolute inset-0 object-contain w-full h-full" /> */}
                <span className="z-20 flex-grow">
                  {step === 0 && <SetupWelcome continueSetup={(e) => updateOrganization(e)} skipSetup={() => skipSetup()} />}
                  {step === 1 && <SetupDepartments existingData={userState?.organization?.departments} continueSetup={(e) => updateOrganization(e)} backSetup={() => setStep(step - 1)} skipSetup={() => skipSetup()} />}
                  {step === 2 && <SetupLocations existingData={userState?.organization?.locations} continueSetup={(e) => updateOrganization(e)} backSetup={() => setStep(step - 1)} skipSetup={() => skipSetup()} />}
                  {step === 3 && <SetupUsers continueSetup={(e) => setStep(step + 1)} backSetup={() => setStep(step - 1)} skipSetup={() => skipSetup()} />}
                  {step === 4 && <SetupFinish continueSetup={(e) => skipSetup()} />}
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function SetupWelcome({ continueSetup, skipSetup }) {
  return (
    <span className="flex flex-col items-center justify-between w-full h-full">
      <span className="w-full flex flex-row-reverse">
        <label className="text-gray-500 font-semibold hover:text-gray-300 cursor-pointer" onClick={() => skipSetup()}>
          Skip
        </label>
      </span>
      <span className="flex flex-col items-center gap-y-1">
        <Logo className="w-20 h-20 pb-2" />
        <label className="font-bold text-3xl">Welcome</label>
        <label className="text-gray-800 text-lg font-semibold">Your organisation wellbeing at a single glance.</label>
      </span>
      <input type="button" className="bg-Secondary p-2 rounded-lg font-semibold cursor-pointer" value="Continue" onClick={() => continueSetup({})} />
    </span>
  );
}

function SetupDepartments({ existingData, continueSetup, backSetup, skipSetup }) {
  const [departments, setDepartments] = useState(existingData || []);
  const [newDepartment, setNewDepartment] = useState("");

  function addNewDepartment() {
    setDepartments([...departments, newDepartment]);
    setNewDepartment("");
  }

  return (
    <span className="flex flex-col items-center justify-between w-full h-full">
      <span className="w-full h-10 items-center flex flex-row justify-between">
        <ChevronLeftIcon className="h-10 w-10 cursor-pointer hover:text-gray-400 rounded-lg" onClick={() => backSetup()} />
        <Logo className="w-8 h-8" />
        <input type="button" value="Skip" className="text-gray-500 p-1 bg-transparent font-semibold hover:text-gray-300 cursor-pointer" onClick={() => skipSetup()} />
      </span>
      <span className="flex flex-col w-full pb-3 flex-grow items-center">
        <label className="font-bold text-xl">Departments</label>
        <label className="font-semibold text-gray-800">Setup your organisations departments.</label>
        <span className="w-full flex-grow pt-1">
          <span className="flex flex-wrap gap-1 overflow-y-auto max-h-40">
            <span className="flex h-8 items-center">
              <input type="text" placeholder="Enter Department..." value={newDepartment} className="bg-gray-200 h-8 border-0 rounded-l-md" onChange={(e) => setNewDepartment(e.target.value)} />
              <PlusIcon className="bg-Secondary h-full p-1 rounded-r-md cursor-pointer" onClick={() => addNewDepartment()} />
            </span>
            {departments.map((dep) => (
              <span className="flex h-8 items-center">
                <label className="bg-gray-200 py-1 px-3 rounded-l-md font-semibold ">{dep}</label>
                <XIcon className="bg-red-400 h-full p-1 rounded-r-md cursor-pointer" onClick={() => setDepartments(departments.filter((d) => d !== dep))} />
              </span>
            ))}
          </span>
        </span>
        <span className="flex items-center gap-x-2 w-full">
          <label className="text-lg font-semibold">Recommended:</label>
          <span className="flex flex-wrap gap-2">
            {["Human Resources", "Marketing", "IT", "Finances", "General"].map((rec) => (
              <input
                type="button"
                value={rec}
                className={" py-1 px-3 rounded-md font-semibold hover:bg-Secondary cursor-pointer select-none " + (departments.includes(rec) ? "bg-Secondary" : "bg-gray-200")}
                onClick={() => (departments.includes(rec) ? setDepartments(departments.filter((dep) => dep != rec)) : setDepartments([...departments, rec]))}
              />
            ))}
          </span>
        </span>
      </span>
      <input type="button" className="bg-Secondary p-2 rounded-lg font-semibold cursor-pointer" value="Continue" onClick={() => continueSetup({ departments })} />
    </span>
  );
}

function SetupLocations({ existingData, continueSetup, backSetup, skipSetup }) {
  const [locations, setLocations] = useState(existingData || []);
  const [newLocation, setNewLocation] = useState("");

  function addNewLocation() {
    setLocations([...locations, newLocation]);
    setNewLocation("");
  }

  return (
    <span className="flex flex-col items-center justify-between w-full h-full">
      <span className="w-full h-10 items-center flex flex-row justify-between">
        <ChevronLeftIcon className="h-10 w-10 cursor-pointer hover:text-gray-400 rounded-lg" onClick={() => backSetup()} />
        <Logo className="w-8 h-8" />
        <input type="button" value="Skip" className="text-gray-500 p-1 bg-transparent font-semibold hover:text-gray-300 cursor-pointer" onClick={() => skipSetup()} />
      </span>
      <span className="flex flex-col w-full pb-3 flex-grow items-center">
        <label className="font-bold text-xl">Locations</label>
        <label className="font-semibold text-gray-800">Setup your organisations locations.</label>
        <span className="w-full flex-grow pt-1">
          <span className="flex flex-wrap gap-1 overflow-y-auto max-h-40">
            <span className="flex h-8 items-center">
              <input type="text" placeholder="Enter Location..." value={newLocation} className="bg-gray-200 h-8 border-0 rounded-l-md" onChange={(e) => setNewLocation(e.target.value)} />
              <PlusIcon className="bg-Secondary h-full p-1 rounded-r-md cursor-pointer" onClick={() => addNewLocation()} />
            </span>
            {locations.map((loc) => (
              <span className="flex h-8 items-center">
                <label className="bg-gray-200 py-1 px-3 rounded-l-md font-semibold ">{loc}</label>
                <XIcon className="bg-red-400 h-full p-1 rounded-r-md cursor-pointer" onClick={() => setLocations(locations.filter((l) => l !== loc))} />
              </span>
            ))}
          </span>
        </span>
      </span>
      <input type="button" className="bg-Secondary p-2 rounded-lg font-semibold cursor-pointer" value="Continue" onClick={() => continueSetup({ locations })} />
    </span>
  );
}

function SetupUsers({ continueSetup, backSetup, skipSetup }) {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState("");

  const [excelDownloaded, setExcelDownloaded] = useState(false);
  const [domainSelected, setDomainSelected] = useState(false);
  const [domain, setDomain] = useState("");

  function registerMany() {
    const input = document.getElementById("fileInput");
    readXlsxFile(input.files[0]).then((rows) => {
      const tempUsers = [];
      rows.forEach((rw, idx) => idx !== 0 && idx !== 1 && idx !== 2 && tempUsers.push({ firstName: rw[0] || "", lastName: rw[1] || "", mail: rw[2] || "", phone: { code: rw[3] || "", number: rw[4] || "" } }));
      setUsers([...users, ...tempUsers]);
    });
  }

  return (
    <span className="flex flex-col items-center justify-between w-full h-full">
      <span className="w-full h-10 items-center flex flex-row justify-between">
        <ChevronLeftIcon className="h-10 w-10 cursor-pointer hover:text-gray-400 rounded-lg" onClick={() => backSetup()} />
        <Logo className="w-8 h-8" />
        <input type="button" value="Skip" className="text-gray-500 p-1 bg-transparent font-semibold hover:text-gray-300 cursor-pointer" onClick={() => skipSetup()} />
      </span>
      <span className="flex flex-col w-full pb-3 flex-grow items-center">
        <label className="font-bold text-xl">Users</label>
        <label className="font-semibold text-gray-800">Invite your organisations users.</label>
        <span className="flex w-full h-full pt-1 overflow-hidden">
          <span className="flex flex-wrap gap-1 max-h-64 overflow-y-auto">
            <span className="flex h-8 items-center">
              <input type="text" placeholder="Enter mail..." value={newUser} className="bg-gray-200 h-8 border-0 rounded-l-md" onChange={(e) => setNewUser(e.target.value)} />
              <PlusIcon
                className="bg-Secondary h-full p-1 rounded-r-md cursor-pointer"
                onClick={() => {
                  setUsers([...users, { mail: newUser }]);
                  setNewUser("");
                }}
              />
            </span>
            {users.map((user) => (
              <span className="flex h-8 items-center">
                <label className="bg-gray-200 py-1 px-3 rounded-l-md font-semibold ">{user?.mail}</label>
                <XIcon className="bg-red-400 h-full p-1 rounded-r-md cursor-pointer" onClick={() => setUsers(users.filter((u) => u.mail !== user.mail))} />
              </span>
            ))}
          </span>
        </span>
        <span className="flex w-full gap-x-2 items-center">
          <label className="font-semibold">Bulk Import Users</label>

          {excelDownloaded ? (
            <input type="file" id="fileInput" className="text-center w-28 text-white font-medium rounded" onChange={(e) => registerMany()} />
          ) : (
            <a href="https://static.wellm8.com/templates/Template.xlsx" onClick={() => setExcelDownloaded(true)} className="py-1 px-2 text-white font-semibold bg-blue-800 rounded-md">
              Download CSV Template
            </a>
          )}
        </span>
        <span className="flex flex-wrap items-center gap-x-2 w-full">
          <label className="flex items-center gap-x-2 font-semibold">
            <input type="checkbox" onClick={(e) => setDomainSelected(e.target.checked)} />
            Allow users to automatically join with company domain.
          </label>
          {domainSelected && <input type="text" placeholder="example.com" value={domain} className="flex-grow rounded-md h-8 bg-gray-300 border-0" onChange={(e) => setDomain(e.target.value)} />}
        </span>
      </span>
      <input type="button" className="bg-Secondary p-2 rounded-lg font-semibold cursor-pointer" value="Continue" onClick={() => continueSetup({ users })} />
    </span>
  );
}

function SetupFinish({ continueSetup }) {
  return (
    <span className="flex flex-col items-center justify-center gap-y-4 w-full h-full">
      <span className="flex flex-col items-center gap-y-1">
        <Logo className="w-20 h-20 pb-2" />
        <label className="text-gray-800 text-3xl font-semibold">Your organisation setup is complete.</label>
      </span>
      <input type="button" className="bg-Secondary text-2xl p-3 rounded-lg font-semibold cursor-pointer" value="Finish" onClick={() => continueSetup({})} />
    </span>
  );
}
