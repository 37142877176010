import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ChatMessage from "./ChatMessage";

export default function ChatMessages({ chat, messages }) {
  const userSelector = useSelector((state) => state.user);
  useEffect(() => document.getElementById("messages-container").scrollTo({ top: document.getElementById("messages-container").scrollHeight, behavior: "smooth" }), [messages?.length]);

  return (
    <div id="messages-container" className="flex flex-col flex-grow gap-y-1 p-2 overflow-x-hidden overflow-y-auto">
      {chat?.messages?.map((message, idx) => (
        <ChatMessage
          previousSender={(idx > 0 ? chat?.messages[idx - 1].sender : "") === message.sender}
          message={message}
          user={(message.sender === chat.user._id.$oid && chat.user) || chat.supports.find((support) => support._id.$oid === message.sender)}
          sender={message.sender === userSelector.user._id.$oid}
        />
      ))}
    </div>
  );
}
