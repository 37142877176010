import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

export default function ListBox({ listClassName, defaultOption, current, options, handleSelection, clearSelection }) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultOption || current || "");

  const handleChange = (e) => {
    setSelected(e);
    setOpen(false);
    handleSelection(e);
  };

  useEffect(() => {
    clearSelection && setSelected("");
  }, [clearSelection]);

  return (
    <div tabIndex="0" onBlur={() => setOpen(false)} className={"relative " + listClassName}>
      <span onClick={() => setOpen(!open)} className="flex justify-between overflow-hidden cursor-pointer shadow p-2 border border-gray-200 h-10 rounded items-center text-center bg-white w-full">
        <label className="pointer-events-none flex-grow">{selected}</label>
        <SelectorIcon className="flex-shrink h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
      {open && (
        <div className="absolute z-20 flex flex-col shadow max-h-60 overflow-y-auto   bg-white w-full">
          {options.map((option) => (
            <label onClick={() => handleChange(option)} className={"p-2 bg-white w-full cursor-pointer hover:bg-gray-200 " + (option === selected ? "bg-blue-200" : "")}>
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
}
