import { configureStore } from "@reduxjs/toolkit";
// import {pendingReducer} from "redux-pending"
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { version } from "../../package.json";
import storage from "redux-persist/lib/storage";
import UserReducer from "../redux/UserReducer";

import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

const persistConfig = {
  key: version,
  storage,
};

const rootReducer = combineReducers({
  user: UserReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
