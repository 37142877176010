import { API_URL, statuses } from "./constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createRequest = async (options) => {
  let route = options.route;
  let requestOptions = { method: options.method, body: options.body, headers: { Authorization: "" } };
  let appendOptions = options.append;
  for (const param of options.params) route = route.replace(String(Object.keys(param)[0]), options.paramData[Object.values(param)[0]]);

  if (options.method === "GET") delete requestOptions.body;
  else requestOptions["headers"]["Content-Type"] = options.ct;
  if (appendOptions === "organization") appendOptions = options.ext.getState().user.user[appendOptions];
  if (options.omitAuth === false) requestOptions["headers"]["Authorization"] = `Bearer ${options.ext.getState().user.user.token}`;

  const request = await fetch(`${API_URL}${options.version}/${route}${appendOptions}${options.arguments}`, requestOptions);

  let response;
  try {
    response = await request.json();
  } catch (e) {
    response = request.statusText;
  }
  if (request.status === 200) return response;
  if (request.status === 422) options.ext.dispatch({ type: "user/logout" });
  if (request.status >= 400) throw response;
  return response;
};

export const buildFromCreator = (creator) => {
  let actions = {};
  let reducers = {};
  for (const c in creator) {
    actions[c] = creator[c].action;
    reducers = { ...reducers, ...creator[c].reducers };
  }

  return [actions, reducers];
};

export const createReducer = (options, action) => {
  return {
    [action.pending]: (s, a) => {
      s.status[options.name] = statuses.pending;
      s.messages[options.name] = options.pending.message;
      if (options.pending.hasOwnProperty("cb")) s = options.pending.cb(s, a);
    },
    [action.fulfilled]: (s, a) => {
      s.status[options.name] = statuses.fulfilled;
      s.messages[options.name] = options.fulfilled.message;
      if (options.fulfilled.hasOwnProperty("cb")) s = options.fulfilled.cb(s, a);
    },
    [action.rejected]: (s, a) => {
      s.status[options.name] = statuses.rejected;
      s.messages[options.name] = options.rejected.message;
      if (options.rejected.hasOwnProperty("cb")) s = options.rejected.cb(s, a);
    },
  };
};

export const createAction = ({ name, actionName, route, method, key, append, omitAuth, ct, version, routeParameters, pending, fulfilled, rejected }) => {
  const action = createAsyncThunk(actionName, async (data, ext) => {
    try {
      const res = await createRequest({
        route: route,
        method: method,
        body: data[key] || {},
        version: version || "v1",
        append: append || "",
        arguments: data.arguments || "",
        omitAuth: omitAuth || false,
        ct: ct || "application/json",
        ext,
        params: routeParameters || [],
        paramData: data,
      });
      return res;
    } catch (error) {
      console.log(error);
      return ext.rejectWithValue(error);
    }
  });

  const reducers = createReducer({ name, actionName, route, method, key, append, omitAuth, ct, routeParameters, pending, fulfilled, rejected }, action);

  return { action, reducers };
};
