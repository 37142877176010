import React, { useState } from "react";
import { XIcon, PlusIcon } from "@heroicons/react/solid";
import ConfirmationDialog from "../../ConfirmationDialog";

const colors = ["gray", "red", "yellow", "green", "blue", "indigo", "purple", "pink"];

export function NewCategory({ confirm }) {
  const [category, setCategory] = useState("");

  function addCategory() {
    const tempCategory = category;
    setCategory("");
    confirm(tempCategory);
  }

  return (
    <span key={"New"} className="flex rounded-lg bg-white shadow-md w-60 h-16 gap-x-2 items-center justify-between">
      <input type="text" placeholder="Enter.." value={category} onChange={(e) => setCategory(e.target.value)} className="w-44 ml-3 h-10 rounded-md border-0 bg-gray-100" />
      <PlusIcon className="w-10 h-full text-white bg-green-400 rounded-r-lg cursor-pointer" onClick={() => addCategory()} />
    </span>
  );
}

export function CategoryCard({ category, confirm }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <span key={category} className="flex rounded-lg bg-white shadow-md gap-x-4 h-16 justify-between">
      <label className={`bg-${colors[category.charCodeAt(0) % colors.length]}-400 h-full p-4 rounded-l-lg text-xl font-semibold`}>{category[0]}</label>
      <label className="my-auto text-center font-semibold text-lg break-all truncate flex-grow">{category}</label>
      <XIcon className="w-10 h-full text-red-400 p-1 cursor-pointer" onClick={() => setExpanded(true)} />
      {expanded && <ConfirmationDialog expanded={expanded} setExpanded={(e) => setExpanded(e)} title="Confirm Deletion" description="Your Department Will Be Deleted" confirm={() => confirm()} />}
    </span>
  );
}
