import React, { useState } from "react";
import routes from "../../SideBarRoutes";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Route } from "react-router-dom";
import SidebarSubmenu from "./SidebarSubmenu";
import { logout } from "../../redux/UserReducer";
import { API_URL } from "../../redux/constants";
import { ReactComponent as Logo } from "../icons/logo.svg";
import { PencilIcon } from "@heroicons/react/solid";
import UserPopup from "../UserEditPopup";

export default function SidebarContent({ closeSidebar }) {
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.user.organization);
  const dispatch = useDispatch();
  const [editPopup, setEditPopup] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-6 justify-between">
        <span className="flex flex-col items-center gap-y-2 justify-between w-full h-28 ">
          <Logo className="flex-grow hidden lg:flex" />
          <label className="text pointer-events-none font-semibold flex-grow text-center">{`${organization.name}`}</label>
          <span className="flex w-full items-center cursor-pointer p-1 bg-gray-50 rounded-md" onClick={() => setEditPopup(true)}>
            <label className="text pointer-events-none font-semibold flex-grow text-center">{`${user.user.firstName} ${user.user.lastName}`}</label>
            <PencilIcon className="h-6" />
          </span>
        </span>
        <ul>
          {routes.map(
            (route) =>
              route.role.includes(user?.user?.role) &&
              (route.routes ? (
                <SidebarSubmenu route={route} key={route.name} selected={() => closeSidebar && closeSidebar()} />
              ) : (
                <li className="relative px-3 py-3" key={route.name}>
                  <NavLink
                    exact
                    onClick={() => closeSidebar && closeSidebar()}
                    to={route.path}
                    className="flex gap-x-2 items-center flex-grow text-Main font-semibold transition-colors duration-150 hover:text-gray-800"
                    activeClassName="text-white dark:text-gray-100"
                  >
                    <Route path={route.path} exact={route.exact}>
                      <span className="absolute inset-y-0 left-0 w-1 bg-Main rounded-tr-lg rounded-br-lg" aria-hidden="true"></span>
                    </Route>
                    {route.name === "Conversations" &&
                      user.conversations?.find(
                        (conv) => conv.supports.find((supp) => supp._id.$oid === user.user._id.$oid) && conv.messages.length > 0 && !conv.messages[conv.messages.length - 1]?.seenBy?.includes(user.user._id.$oid)
                      ) && <div className="w-2 h-2 bg-red-500 rounded-full" />}
                    <span className="text-xl">{route.name}</span>
                  </NavLink>
                </li>
              ))
          )}
        </ul>
      </div>
      <span className="flex flex-col">
        {["SuperAdmin"].includes(user?.user?.role) && (
          <span className="flex flex-col">
            <label className="font-semibold">Current Organisation:</label>
            <NavLink to={"/organizations"} onClick={() => closeSidebar && closeSidebar()} className="w-full text-center hover:bg-gray-300 rounded-lg font-bold text-xl text-Main">
              {user.selectedOrganization === "0" ? "No Organisation" : user?.organization?.name}
            </NavLink>
          </span>
        )}
        <label className="w-full text-center p-4 text-xl font-semibold text-red-600 hover:text-red-400 cursor-pointer" onClick={() => dispatch(logout({}))}>
          Log Out
        </label>
      </span>
      {editPopup && <UserPopup user={user.user} hide={["departments", "archive", "locations", "role"]} setOpen={(e) => setEditPopup(e)} />}
    </>
  );
}
