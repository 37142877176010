import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Transition, Dialog } from "@headlessui/react";
import { UserActions } from "../../redux/UserActions";
import { XIcon } from "@heroicons/react/solid";
import UserActivity from "../User/UserActivity";
import UserPopup from "../UserEditPopup";

export default function AdminsAndSupportsTab() {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [admins, setAdmins] = useState([]);
  const [isAssigning, setIsAssigning] = useState(false);

  function assignAdmins(add) {
    add.forEach((a) => a.role !== "User" && dispatch(UserActions.updateUser({ user: a.id, newData: JSON.stringify({ role: a.role }) })));
    dispatch(UserActions.getOrganizationUsers({ code: userSelector.selectedOrganization }));
  }

  useEffect(() => setAdmins(userSelector.users.filter((user) => ["Admin", "Support"].includes(user.role))), [userSelector.users]);
  useEffect(() => dispatch(UserActions.getOrganizationUsers({ code: userSelector.selectedOrganization })), []);

  return (
    <div className="w-full h-full overflow-hidden flex-grow flex flex-col gap-y-4">
      <span className="flex gap-x-2 items-center">
        <label className="text-xl font-semibold">Manage your admin and support users</label>
        <input type="button" className="p-2 font-semibold text-white rounded-lg bg-blue-600 cursor-pointer" value="Assign Seat" onClick={() => setIsAssigning(true)} />
      </span>
      <div className="flex flex-wrap justify-start gap-4 p-4 max-h-full overflow-y-auto">
        {admins.map((user) => (
          <UserCard user={user} />
        ))}
      </div>
      {isAssigning && <AdminAssignment expanded={isAssigning} setExpanded={setIsAssigning} confirm={(e) => assignAdmins(e)} />}
    </div>
  );
}

function CardField({ label, value, valueClass }) {
  return (
    <span className="flex truncate break-all items-center gap-x-2">
      <label className="text-sm text-gray-600 ">{label}</label>
      <label className={"text-sm" + valueClass}>{value}</label>
    </span>
  );
}

function AdminAssignment({ expanded, setExpanded, confirm }) {
  const [assignments, setAssignments] = useState([]);
  const userSelector = useSelector((state) => state.user);

  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");

  useEffect(
    () =>
      setUsers(
        userSelector.users.filter(
          (user) => ["User"].includes(user.role) && user.status === "active" && (user.anonymous ? user.userName : `${user.firstName} ${user.lastName}`).toLowerCase().trim().includes(name.toLowerCase().trim())
        )
      ),
    [name, userSelector.users]
  );
  return (
    <Transition appear show={expanded} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 h-screen w-screen" onClose={() => setExpanded(false)}>
        <div className="min-h-screen  text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-20" leave="ease-in duration-200" leaveFrom="opacity-30" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <div className="flex w-screen h-screen items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-xl h-auto p-6 transition-all transform bg-white shadow-xl rounded-2xl">
                <span className="flex flex-col gap-y-4">
                  <label className="text-xl font-semibold">Assign Seats</label>
                  <div className="flex flex-col gap-y-1">
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="rounded-lg" placeholder="Filter Users" />
                    <span className="h-96 overflow-y-auto flex flex-col gap-y-1">
                      {users.map((user) => (
                        <ChangeUserCard user={user} select={(id, role) => setAssignments([...assignments.filter((ass) => ass.id !== id), { id, role }])} />
                      ))}
                    </span>
                  </div>
                  <div className="flex flex-row justify-evenly w-full">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 font-medium text-white bg-blue-400 hover:bg-blue-200 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={() => setExpanded(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 font-medium text-white bg-green-500 hover:bg-green-300 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={() => {
                        confirm(assignments);
                        setExpanded(false);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function ChangeUserCard({ user, select }) {
  const [option, setOption] = useState("");

  useEffect(() => option !== "" && select(user._id.$oid, option), [option]);

  return (
    <span className="flex border rounded-lg p-1 justify-between items-center">
      <label>{user.anonymous ? user.userName : `${user.firstName} ${user.lastName}`}</label>
      <span className="flex items-center divide-x-2 p-1 bg-gray-100 rounded-lg">
        {!["User", ""].includes(option) && <XIcon className="w-10 h-10 text-red-500 hover:bg-gray-300 rounded-l cursor-pointer" onClick={() => setOption("User")} />}
        <input
          type="button"
          value="Support"
          onClick={() => setOption("Support")}
          className={"hover:bg-green-300 font-semibold cursor-pointer p-2 rounded-l-lg " + (option === "Support" ? "bg-green-300" : "bg-gray-100")}
        />
        <input type="button" value="Admin" onClick={() => setOption("Admin")} className={"hover:bg-green-300 p-2 font-semibold cursor-pointer rounded-r-lg " + (option === "Admin" ? "bg-green-300" : "bg-gray-100")} />
      </span>
    </span>
  );
}

function UserCard({ user }) {
  const userSelector = useSelector((state) => state.user);
  const [edit, setEdit] = useState(false);

  return (
    <div className="flex flex-col p-3 gap-y-4 w-72 h-64 justify-evenly bg-white shadow-lg rounded-lg">
      <span className="flex flex-col items-start gap-x-2" to={"/users/" + String(user?._id?.$oid)}>
        <label className="font-semibold text-md pointer-events-none">{`${user.firstName} ${user.lastName}`}</label>
        <label className="text-xs text-gray-600 truncate break-all pointer-events-none">{String(user.mail)}</label>
      </span>
      <span className="flex flex-col">
        <CardField label="Phone Number:" value={user.phone.number ? `+${user.phone.code || ""} ${user.phone.number}` : "No Phone"} />
        <CardField label="Departments:" value={user.departments.join(", ") || "No Departments"} />
        <CardField label="Locations:" value={user.locations.join(", ") || "No Locations"} />
        <CardField label="Position:" value={user.position || "No Position"} />
        {["User"].includes(user.role) && (
          <span className="flex truncate break-all items-center gap-x-2">
            <label className="text-sm text-gray-600 ">Activity:</label>
            <UserActivity active={user.active} />
          </span>
        )}
      </span>
      {["Admin", "SuperAdmin"].includes(userSelector.user.role) && <input type="button" value="Edit" className="w-full bg-blue-400 text-white rounded cursor-pointer p-1" onClick={() => setEdit(true)} />}
      {edit && <UserPopup user={user} hide={["name", "phone"]} setOpen={() => setEdit(false)} />}
    </div>
  );
}
