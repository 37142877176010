import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import PasswordInput from "./Helper/PasswordInput";

export default function Login() {
  const query = new URLSearchParams(window.location.search);
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [mail, setMail] = useState(query.get("mail") || "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("Login");

  function login() {
    if (password === "") setError("Password is empty.");
    else if (mail === "") setError("Mail is empty.");
    else dispatch(UserActions.login({ user: JSON.stringify({ password, mail }) }));
  }

  useEffect(() => setError("Login"), [password, mail]);
  useEffect(() => setError(userSelector.messages.login), [userSelector.messages.login]);

  return (
    <div className="flex w-screen h-screen items-center justify-center bg-blue-300">
      <div className="flex flex-col flex-grow xl:flex-row overflow-y-auto h-screen xl:h-3/5 max-w-5xl bg-white lg:rounded-lg shadow-xl">
        <img aria-hidden="true" className="object-cover h-28 xl:h-auto xl:max-w-2xl shadow-xl" src="/LoginPanel.png" alt="Well Being" />
        <span className="flex flex-col flex-grow xl:gap-y-2 p-6 2xl:justify-between justify-center gap-y-6">
          <label className="text-2xl font-bold text-center">Login</label>
          <span className="flex flex-col gap-y-4">
            <span className="flex flex-col">
              <label className="font-semibold">Mail</label>
              <input type="email" value={mail} onChange={(e) => setMail(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">Password</label>
              <PasswordInput setValue={(e) => setPassword(e)} handleKeyPress={(e) => e.key === 'Enter' && login()}/>
            </span>
            <span className="flex justify-between">
              <Link to={`/create_account?mail=${mail}`} className="text-sm font-medium w-full text-left text-purple-600 dark:text-purple-400 hover:underline  cursor-pointer bg-white" type="button">
                Create Account
              </Link>
              <Link to={`/forgot_password?mail=${mail}`} className="text-sm font-medium w-full text-right text-purple-600 dark:text-purple-400 hover:underline  cursor-pointer bg-white" type="button">
                Forgot your password?
              </Link>
            </span>
          </span>
          <input
            type="button"
            disabled={error !== "Login"}
            value={error}
            className={`py-2  text-xl rounded-md font-semibold text-white ${
              userSelector.status.login === "rejected" && error !== "Login" ? "bg-red-600 hover:bg-red-600" : "hover:bg-blue-400 cursor-pointer bg-blue-600"
            }`}
            onClick={() => login()}
          />
        </span>
      </div>
    </div>
  );
}
