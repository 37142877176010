import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { API_URL } from "../../redux/constants";
import { getUserList, UserActions } from "../../redux/UserActions";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import User from "./User";

export default function UserList(props) {
  const userSelector = useSelector((state) => state.user);
  const activeUser = useParams().user;

  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let tempRows = userSelector.conversations.filter((row) => (row.user.anonymous ? `${row.user.userName}` : `${row.user.firstName}${row.user.lastName}`).toLowerCase().includes(filter.toLowerCase()));
    tempRows = tempRows.sort((a, b) => new Date(b.messages[b.messages?.length - 1]?.date.$date) - new Date(a.messages[a.messages?.length - 1]?.date.$date));
    setRows(tempRows);
  }, [userSelector.conversations, filter]);

  useEffect(() => setLoading(userSelector.status.getConversations === "pending"), [userSelector.status.getConversations]);

  return (
    <div className={"flex flex-col h-screen overflow-y-auto  bg-white flex-grow " + (activeUser ? "max-w-xs hidden xl:flex" : "")}>
      <input type="text" className="h-10 m-2 text-black border-0 bg-gray-200 rounded-lg" placeholder="Filter..." onChange={(e) => setFilter(e.target.value)} />
      {rows.length === 0 && <label className="w-full text-center p-2 text-xl font-bold">{loading ? "Loading" : "No Conversations"}</label>}
      {rows.map((chat) => (
        <User chat={chat} user={userSelector.user._id.$oid} active={chat.user._id.$oid === activeUser} />
      ))}
    </div>
  );
}
