import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions";
import { NewCategory, CategoryCard } from "./Helpers/Categories";

export default function LocationsTab() {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [locations, setLocations] = useState([]);

  useEffect(() => dispatch(UserActions.getOrganization({ code: userSelector.selectedOrganization })), []);

  const removeLocation = (location) => dispatch(UserActions.updateOrganization({ code: userSelector.selectedOrganization, data: JSON.stringify({ locations: [...locations.filter((loc) => loc != location)] }) }));
  const addLocation = (location) => dispatch(UserActions.updateOrganization({ code: userSelector.selectedOrganization, data: JSON.stringify({ locations: [...locations, location] }) }));

  useEffect(() => setLocations(userSelector.organization.locations), [userSelector.organization]);

  return (
    <div className="flex-grow flex flex-col gap-y-4 overflow-y-auto">
      <label className="text-xl font-semibold">Add or remove locations for better organisation structure</label>
      <div className="flex flex-wrap gap-4">
        <NewCategory confirm={(e) => addLocation(e)} />
        {locations.map((location) => (
          <CategoryCard category={location} confirm={() => removeLocation(location)} />
        ))}
      </div>
    </div>
  );
}
