import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/solid";
import { UserActions } from "../redux/UserActions";
import UserRegister from "./User/UserRegisterForm";
import UserPopup from "./UserEditPopup";
import UserTitle from "./User/UserTitle";
import UserActivity from "./User/UserActivity";
import MultiSelectListBox from "./Helper/MultiSelectListBox";

export default function ActiveUsers() {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);
  const { status } = useParams();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    let tempUsers = userSelector.users?.filter((user) => user.status === status);
    tempUsers = tempUsers.filter((user) => user.role === "User");
    if (name.length > 0) tempUsers = tempUsers.filter((user) => (user.anonymous ? `${user.userName}` : `${user.firstName}${user.lastName}`).toLowerCase().includes(name.toLowerCase()));
    if (departments.length > 0) tempUsers = tempUsers.filter((user) => departments.some((item) => user.departments.includes(item)));
    if (locations.length > 0) tempUsers = tempUsers.filter((user) => locations.some((item) => user.locations.includes(item)));
    setUsers(tempUsers);
  }, [name, departments, locations, userSelector.users]);

  useEffect(() => {
    dispatch(UserActions.getOrganization({ code: userSelector.selectedOrganization }));
    dispatch(UserActions.getOrganizationUsers({ code: userSelector.selectedOrganization }));
  }, [status]);

  useEffect(() => setLoading(userSelector.status.getOrganizationUsers === "pending"), [userSelector.status.getOrganizationUsers]);

  return (
    <div className="w-full h-screen overflow-hidden flex flex-col bg-gray-100">
      <span className="flex flex-col xl:flex-row gap-y-2 xl:gap-x-4 w-full justify-between items-center p-4 bg-white shadow-lg">
        <span className="flex items-center gap-x-4 w-full">
          <label className="font-semibold w-24">Name:</label>
          <input type="text" placeholder="Enter Name..." className="flex-grow h-10 rounded-md shadow border-gray-200" value={name} onChange={(e) => setName(e.target.value)} />
        </span>
        <span className="flex items-center gap-x-4 w-full">
          <label className="font-semibold w-24">Departments:</label>
          <MultiSelectListBox className="flex-grow" options={userSelector.organization.departments} handleSelected={(e) => setDepartments(e)} />
        </span>
        <span className="flex items-center gap-x-4 w-full">
          <label className="font-semibold w-24">Locations:</label>
          <MultiSelectListBox options={userSelector.organization.locations} handleSelected={(e) => setLocations(e)} />
        </span>
        {["Admin", "SuperAdmin"].includes(userSelector.user.role) && (
          <span className="hidden xl:flex gap-x-2 items-center justify-evenly ">
            <PlusIcon className="flex h-10 w-10 p-1 cursor-pointer hover:bg-gray-300 rounded-full" onClick={() => setShowAddUserModal(true)} />
          </span>
        )}
      </span>
      {users.length === 0 ? (
        <label className="w-full text-center text-xl font-semibold p-4">{loading ? "Loading..." : "No Users"}</label>
      ) : (
        <div className="overflow-y-auto flex flex-wrap justify-center items-start gap-6 p-4">
          {users.map((user) => (
            <UserCard user={user} />
          ))}
        </div>
      )}
      <UserRegister isOpen={showAddUserModal} setIsOpen={setShowAddUserModal} />
    </div>
  );
}

function CardField({ label, value, valueClass }) {
  return (
    <span className="flex truncate break-all items-center gap-x-2">
      <label className="text-sm text-gray-600 ">{label}</label>
      <label className={"text-sm" + valueClass}>{value}</label>
    </span>
  );
}

export function UserCard({ user }) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  return (
    <div className="flex flex-col p-3 gap-y-4 w-72 h-64 justify-between bg-white shadow-lg rounded-lg">
      <UserTitle user={user} />
      <span className="flex flex-col">
        {!user.anonymous && <CardField label="Phone Number:" value={user.phone.number ? `+${user.phone.code || ""} ${user.phone.number}` : "No Phone"} />}
        <CardField label="Department:" value={user.departments.join(", ") || "No Departments"} />
        <CardField label="Locations:" value={user.locations.join(", ") || "No Locations"} />
        {!user.anonymous && <CardField label="Position:" value={user.position || "No Position"} />}
        <CardField label="Gender:" value={user.gender} />
        {["User"].includes(user.role) && (
          <span className="flex truncate break-all items-center gap-x-2">
            <label className="text-sm text-gray-600 ">Activity:</label>
            <UserActivity active={user.active} />
          </span>
        )}
      </span>
      <span className="flex h-10 gap-x-2">
        {["SuperAdmin"].includes(userSelector.user.role) && (
          <input type="button" value="Delete" className="flex-grow bg-red-400 rounded text-white font-semibold cursor-pointer" onClick={() => dispatch(UserActions.deleteUser({ user: user._id.$oid }))} />
        )}
        {["Admin", "SuperAdmin"].includes(userSelector.user.role) && <input type="button" value="Edit" className="flex-grow bg-blue-400 text-white font-semibold rounded cursor-pointer" onClick={() => setEdit(true)} />}
      </span>
      {edit && <UserPopup user={user} hide={["name", "phone"]} setOpen={() => setEdit(false)} />}
    </div>
  );
}
