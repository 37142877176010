import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions";
import { NewCategory, CategoryCard } from "./Helpers/Categories";

export default function DepartmentsTab() {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [departments, setDepartments] = useState([]);

  const removeDepartment = (department) => dispatch(UserActions.deleteDepartment({ organization: userSelector.selectedOrganization, department }));
  const addDepartment = (department) => dispatch(UserActions.updateOrganization({ data: JSON.stringify({ departments: [...departments, department] }), code: userSelector.selectedOrganization }));

  useEffect(() => setDepartments(userSelector.organization?.departments || []), [userSelector.organization]);

  console.log(departments);

  return (
    <div className="flex-grow flex flex-col overflow-y-auto gap-y-4">
      <label className="text-xl font-semibold">Add or remove departments for better organisation structure</label>
      <div className="flex flex-wrap w-full gap-4">
        <NewCategory confirm={(e) => addDepartment(e)} />
        {departments.map((department) => (
          <CategoryCard category={department} confirm={() => removeDepartment(department)} />
        ))}
      </div>
    </div>
  );
}
