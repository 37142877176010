import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { UserActions } from "../redux/UserActions";
import { Link } from "react-router-dom";

export default function Priorities() {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [priorities, setPriorities] = useState([]);

  useEffect(() => setPriorities(userState.priorities), [userState.priorities]);
  useEffect(() => dispatch(UserActions.priorities({ code: userState.selectedOrganization })), []);
  useEffect(() => setLoading(userState.status.priority === "pending"), [userState.status.priority]);

  return (
    <div className="flex flex-col w-full h-full max-h-full overflow-hidden bg-gray-100 p-3">
      <label className="text-2xl font-semibold p-2 w-full text-center">Priorities</label>
      <div className="flex flex-wrap max-h-full justify-center overflow-y-auto gap-4 py-2">
        {priorities.length === 0 ? <label className="w-full text-center text-xl font-semibold">{loading ? "Loading..." : "No Priorities"}</label> : priorities.map((priority) => <UserCard user={priority} />)}
      </div>
    </div>
  );
}

function UserCard({ user }) {
  return (
    <div className="flex flex-col rounded-lg shadow-lg bg-white xl:h-72 w-80 items-center justify-between">
      <span className="flex flex-col items-center gap-x-2 h-14 bg-red-500 w-full rounded-t-lg p-1">
        <label className="font-semibold text-xl text-center text-white">{user.anonymous ? user.userName : user.firstName + " " + user.lastName}</label>
        {user.phone.number !== "" && <label className="text-sm text-center text-white px-2">{"+" + user.phone.code + " " + user.phone.number}</label>}
      </span>
      <span className="flex flex-col py-4 px-2 xl:py-0">
        {user.priority.map((priority) => (
          <label className="text-red-600 text-center">{priority}</label>
        ))}
      </span>
      <span className="flex flex-row w-full p-4 gap-x-2">
        <Link to={`/conversations/${String(user._id.$oid)}`} className="py-2 w-full text-white text-center rounded font-medium bg-green-600 hover:bg-green-500 cursor-pointer">
          Contact
        </Link>
        <Link className="py-2 w-full text-white text-center rounded font-medium bg-blue-600 hover:bg-blue-500 cursor-pointer" to={`/users/${String(user._id.$oid)}`}>
          Profile
        </Link>
      </span>
    </div>
  );
}
