import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const query = new URLSearchParams(window.location.search);
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [mail, setMail] = useState(query.get("mail") || "");
  const [error, setError] = useState("Send Mail");

  function forgotPassword() {
    if (mail == "") setError("Mail is empty.");
    else if (!mail.includes("@")) setError("Invalid mail.");
    else dispatch(UserActions.passwordToken({ user: JSON.stringify({ mail }) }));
  }

  useEffect(() => setError("Send Mail"), [mail]);
  useEffect(() => setError(userSelector.messages.passwordToken), [userSelector.messages.passwordToken]);

  return (
    <div className="flex w-screen h-screen items-center justify-center bg-blue-300">
      <div className="flex flex-col flex-grow xl:flex-row overflow-y-auto h-screen xl:h-3/5 max-w-5xl bg-white lg:rounded-lg shadow-xl">
        <img aria-hidden="true" className="object-cover h-24 xl:h-auto xl:max-w-2xl shadow-xl" src="/LoginPanel.png" alt="Well Being" />
        <span className="flex flex-col flex-grow 2xl:gap-y-2 gap-y-6 p-4 2xl:justify-between justify-center">
          <label className="text-2xl font-bold text-center">Forgot Password</label>
          <span className="flex flex-col gap-y-1">
            <span className="flex flex-col">
              <label className="font-semibold">Mail</label>
              <input type="email" value={mail} onChange={(e) => setMail(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <Link to={`/login?mail=${mail}`} className="text-sm font-medium w-full text-right text-purple-600 dark:text-purple-400 hover:underline  cursor-pointer bg-white" type="button">
              Log in
            </Link>
          </span>
          <input
            type="button"
            disabled={error !== "Send Mail"}
            value={error}
            className={`py-2 text-xl rounded-md font-semibold text-white ${
              userSelector.status.passwordToken === "rejected" && error !== "Send Mail" ? "bg-red-600 hover:bg-red-600" : "hover:bg-blue-400 cursor-pointer bg-blue-600"
            }`}
            onClick={() => forgotPassword()}
          />
        </span>
      </div>
    </div>
  );
}
