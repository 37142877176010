import React, { useState } from "react";
import { EyeIcon, EyeOffIcon, LockClosedIcon } from "@heroicons/react/solid";

export default function PasswordInput({ setValue, handleKeyPress }) {
  const [visible, setVisible] = useState(false);
  return (
    <span className="flex items-center justify-between rounded-md bg-gray-100 p-1">
      <input type={visible ? "text" : "password"} className="flex-grow bg-transparent border-0 rounded-md" onChange={(e) => setValue(e.target.value)} onKeyPress={handleKeyPress}/>
      {visible ? (
        <EyeOffIcon className="h-8 p-1 hover:bg-gray-300 rounded-md cursor-pointer" onClick={() => setVisible(false)} />
      ) : (
        <EyeIcon className="h-8 p-1 hover:bg-gray-300 rounded-md cursor-pointer" onClick={() => setVisible(true)} />
      )}
    </span>
  );
}
