import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";

function SidebarSubmenu({ route, selected }) {
  const userState = useSelector((state) => state.user);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  function handleDropdownMenuClick() {
    setIsDropdownMenuOpen(!isDropdownMenuOpen);
  }

  return (
    <li className="relative px-3 py-3" key={route.name}>
      <button className="inline-flex items-center justify-between text-Main w-full font-semibold transition-colors duration-150 hover:text-gray-700" onClick={handleDropdownMenuClick} aria-haspopup="true">
        <span className="flex gap-x-2 items-center">
          {route.name == "Conversations" && userState.conversations?.find((conv) => !conv.messages[conv.messages.length - 1]?.seenBy?.includes(userState.user._id.$oid)) && (
            <div className="w-2 h-2 bg-red-500 rounded-full" />
          )}
          <span className="text-xl">{route.name}</span>
        </span>
        <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
      </button>
      <Transition
        show={isDropdownMenuOpen}
        enter="transition-all ease-in-out duration-300"
        enterFrom="opacity-25 max-h-0"
        enterTo="opacity-100 max-h-xl"
        leave="transition-all ease-in-out duration-300"
        leaveFrom="opacity-100 max-h-xl"
        leaveTo="opacity-0 max-h-0"
      >
        <ul className="p-2 mt-2 space-y-2 overflow-hidden font-medium text-Main rounded-md shadow-inner bg-gray-200" aria-label="submenu">
          {route.routes.map(
            (r) =>
              r.role.includes(userState.user.role) && (
                <li className="px-2 py-1 flex items-center gap-x-2 transition-colors duration-150 hover:text-gray-700" key={r.name}>
                  <Link className="w-full text-lg" onClick={() => selected && selected()} to={r.path}>
                    {r.name}
                  </Link>
                </li>
              )
          )}
        </ul>
      </Transition>
    </li>
  );
}

export default SidebarSubmenu;
