import React, { useEffect, useState } from "react";
import PasswordInput from "./Helper/PasswordInput";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";

export default function CreateOrganization() {
  const history = useHistory();

  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [registerClicked, setRegisterClicked] = useState(false);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [error, setError] = useState("Create");

  function createOrganization() {
    setRegisterClicked(true);
    if (name === "") setError("Organisation name is empty.");
    else if (mail === "") setError("Mail is empty.");
    else dispatch(UserActions.createOrganization({ organization: JSON.stringify({ name, contact: { mail } }) }));
  }

  useEffect(() => registerClicked && userSelector.status.createOrganization === "fulfilled" && history.push(`/login?mail=${mail}`), [userSelector.status.createOrganization]);
  useEffect(() => setError("Create"), [name, mail]);
  useEffect(() => setError(userSelector.messages.createOrganization), [userSelector.messages.createOrganization]);

  return (
    <div className="flex w-screen h-screen items-center justify-center bg-blue-300">
      <div className="flex flex-col flex-grow 2xl:flex-row overflow-hidden h-screen xl:h-3/5 max-w-5xl bg-white lg:rounded-lg shadow-xl">
        <img aria-hidden="true" className="object-cover h-24 2xl:h-auto max-w-2xl shadow-xl" src="/LoginPanel.png" alt="Well Being" />
        <span className="flex flex-col flex-grow 2xl:gap-y-2 p-4 2xl:justify-between justify-center gap-y-6 overflow-y-auto">
          <label className="text-2xl font-bold text-center">Create Organisation</label>
          <span className="flex flex-col gap-y-1">
            <span className="flex flex-col">
              <label className="font-semibold">Organization Name</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <span className="flex flex-col">
              <label className="font-semibold">Administrator Mail</label>
              <input type="text" value={mail} onChange={(e) => setMail(e.target.value)} className="rounded-md bg-gray-100 border-0" />
            </span>
            <Link to={`/login?mail=${mail}`} className="text-sm font-medium w-full text-right text-purple-600 dark:text-purple-400 hover:underline  cursor-pointer bg-white" type="button">
              Already have an organisation?
            </Link>
          </span>
          <input
            type="button"
            disabled={error !== "Create"}
            value={error}
            className={`py-2 text-xl rounded-md font-semibold text-white ${
              userSelector.status.createOrganization === "rejected" && error !== "Create" ? "bg-red-600 hover:bg-red-600" : "hover:bg-blue-400 cursor-pointer bg-blue-600"
            }`}
            onClick={() => createOrganization()}
          />
        </span>
      </div>
    </div>
  );
}
