import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import { API_URL } from "../redux/constants";
import { XIcon, CheckIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";

import ListBox from "./Helper/ListBox";
import MultiSelectListBox from "./Helper/MultiSelectListBox";
import User from "./Conversation/User";

export default function UserPopup({ user, setOpen, hide = [] }) {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(true);
  const [mailSent, setMailSent] = useState(false);
  const [archivePressed, setArchivePressed] = useState(false);

  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [userName, setUserName] = useState(user.userName);
  const [departments, setDepartments] = useState(user.departments);
  const [locations, setLocations] = useState(user.locations);
  const [phone, setPhone] = useState(user.phone);
  const [role, setRole] = useState(user.role);
  const [organization, setOrganization] = useState(user.organization);

  function closeModal() {
    setOpen();
    setIsOpen(false);
  }

  function archiveUser(status) {
    dispatch(UserActions.updateUser({ user: String(user._id.$oid), newData: JSON.stringify({ status: status }) }));
    closeModal();
  }

  function resetUserPassword() {
    dispatch(UserActions.passwordToken({ user: JSON.stringify({ mail: String(user.mail) }) }));
    setMailSent(true);
  }

  function updateUser() {
    const newData = {};

    firstName != user.firstName && Object.assign(newData, { firstName });
    lastName != user.lastName && Object.assign(newData, { lastName });
    userName != user.userName && Object.assign(newData, { userName });
    phone.number != user.phone.number && Object.assign(newData, { phone });
    departments != user.departments && Object.assign(newData, { departments });
    locations != user.locations && Object.assign(newData, { locations });
    role != user.role && Object.assign(newData, { role });
    organization != user.organization && Object.assign(newData, { organization });

    if (isNaN(phone.number)) setError("number");
    else if (isNaN(phone.code)) setError("code");
    else {
      dispatch(UserActions.updateUser({ user: String(user._id.$oid), newData: JSON.stringify(newData) }));
      closeModal();
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-hidden" onClose={closeModal}>
        <div className="min-h-screen text-center">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="absolute w-screen h-screen backdrop-filter backdrop-blur bg-black bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block h-screen md:h-full w-full max-w-lg p-8 text-left align-middle transition-all transform bg-gray-50 shadow-xl md:rounded-md">
              <div className="flex flex-col justify-center h-full w-full gap-y-4">
                <span className="flex flex-col xl:flex-row items-center justify-between gap-2">
                  <span className="flex flex-col flex-grow truncate items-start">
                    <label className="font-semibold text-lg pointer-events-none">{user.anonymous ? user.userName : `${user.firstName} ${user.lastName}`}</label>
                    <label className="text-sm text-gray-600 pointer-events-none">{String(user.mail)}</label>
                    <label className="text-sm pointer-events-none">{user.phone.number ? `+${user.phone.code || ""} ${user.phone.number}` : "No Phone Number"}</label>
                  </span>
                  {!hide.includes("archive") && (
                    <span className="flex flex-row xl:flex-col gap-2 w-36">
                      {archivePressed ? (
                        <span className="flex w-full bg-gray-200 rounded-md">
                          <button className="flex w-full bg-red-500 rounded-l justify-center " onClick={() => setArchivePressed(false)}>
                            <XIcon className="h-8 w-8 text-white" />
                          </button>
                          <button className="flex w-full bg-green-500 rounded-r justify-center " onClick={() => archiveUser(user.status === "active" ? "archived" : "active")}>
                            <CheckIcon className="h-8 w-8 text-white" />
                          </button>
                        </span>
                      ) : (
                        <button type="button" className="p-2 w-full font-medium text-white bg-purple-500 rounded-md hover:bg-purple-400" onClick={() => setArchivePressed(true)}>
                          {user.status === "archived" ? "Restore" : "Archive"}
                        </button>
                      )}
                      <button type="button" className="p-2 w-full font-medium text-white bg-blue-500 rounded-md hover:bg-blue-400 " disabled={user.randomToken ? true : false} onClick={() => resetUserPassword()}>
                        {mailSent ? userSelector.messages.passwordToken : "Reset Password"}
                      </button>
                    </span>
                  )}
                </span>

                <span className="flex flex-col justify-start gap-y-2">
                  {(!hide.includes("name") || ["SuperAdmin"].includes(userSelector.user.role)) && (
                    <span className="flex flex-grow flex-wrap gap-4">
                      <span className="flex flex-grow flex-col">
                        <label>First Name:</label>
                        <input className="border-0 flex-grow bg-gray-100 rounded shadow" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </span>
                      <span className="flex flex-grow flex-col">
                        <label>Last Name:</label>
                        <input className="border-0 flex-grow bg-gray-100 rounded shadow" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </span>
                      {["SuperAdmin"].includes(userSelector.user.role) && (
                        <span className="flex flex-grow flex-col">
                          <label>User Name:</label>
                          <input className="border-0 flex-grow bg-gray-100 rounded shadow" type="text" value={userName} onChange={(e) => setUserName(e.target.value)} />
                        </span>
                      )}
                    </span>
                  )}
                  {!hide.includes("phone") && (
                    <span className="flex flex-wrap gap-x-4">
                      <span className="flex flex-col w-20">
                        <label>Country:</label>
                        <input
                          className={`border-gray-300 bg-gray-100 rounded shadow ${error === "code" && "border-red-500"}`}
                          type="text"
                          value={phone.code}
                          onChange={(e) => setPhone({ ...phone, code: e.target.value })}
                        />
                      </span>
                      <span className="flex flex-col flex-grow">
                        <label>Phone:</label>
                        <input
                          className={`border-gray-300 bg-gray-100 rounded shadow ${error === "number" && "border-red-500"}`}
                          type="text"
                          value={phone.number}
                          onChange={(e) => setPhone({ ...phone, number: e.target.value })}
                        />
                      </span>
                    </span>
                  )}
                  {!hide.includes("departments") && (
                    <label>
                      Departments:
                      <MultiSelectListBox options={userSelector.organization.departments} defaultValues={departments} handleSelected={(e) => setDepartments(e)} />
                    </label>
                  )}
                  {!hide.includes("locations") && (
                    <label>
                      Locations:
                      <MultiSelectListBox options={userSelector.organization.locations} defaultValues={locations} handleSelected={(e) => setLocations(e)} />
                    </label>
                  )}
                  {!hide.includes("role") && (
                    <label>
                      Role:
                      <ListBox listClassName="w-full" options={["User", "Support", "Admin"]} current={role} handleSelection={(sel) => setRole(sel)} />
                    </label>
                  )}
                  {["SuperAdmin"].includes(userSelector.user.role) && (
                    <label>
                      Organization:
                      <ListBox listClassName="w-full" options={userSelector.organizations.map((o) => o.code)} current={organization} handleSelection={(sel) => setOrganization(sel)} />
                    </label>
                  )}
                </span>

                <div className="flex justify-evenly">
                  <button type="button" className="px-8 py-2 font-medium text-white bg-red-500 rounded-md hover:bg-red-400" onClick={closeModal}>
                    Close
                  </button>
                  <button type="button" className="px-12 py-2 font-medium text-white bg-green-500 rounded-md hover:bg-green-400" onClick={() => updateUser()}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
