import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions";
import { socket } from "../../App";

export default function ChatInput({ conversation }) {
  const userSelector = useSelector((user) => user.user);
  const [body, setBody] = useState("");
  const dispatch = useDispatch();

  function sendMessage() {
    if (conversation.messages.length > 0) socket.emit("send_message", { conversation: conversation._id.$oid, body, sender: userSelector.user._id.$oid });
    else dispatch(UserActions.createConversation({ data: JSON.stringify({ userId: conversation.user._id.$oid, body, sender: userSelector.user._id.$oid }) }));
    setBody("");
  }

  return (
    <div className="flex p-2 gap-x-2 ">
      <input
        id="message-input"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        onKeyDown={(e) => e.code === "Enter" && body.trim().length > 0 && sendMessage()}
        className="flex-1 rounded border border-gray-400 "
      />
      <button onClick={() => body.trim().length > 0 && sendMessage()} className="flex-none rounded-lg bg-blue-100 text-lg font-medium py-2 px-6 ">
        Send
      </button>
    </div>
  );
}
