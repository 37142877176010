import { createAction, buildFromCreator } from "./ReduxTemplates";
import { statuses } from "./constants";

export const UserCreator = {
  login: createAction({
    name: "login",
    actionName: "user/login",
    route: "login",
    method: "POST",
    key: "user",
    version: "v2",
    omitAuth: true,
    pending: { message: "Logging in..." },
    fulfilled: {
      cb: (s, a) => {
        if (["SuperAdmin", "Admin", "Support"].includes(a.payload.role)) {
          s.user = a.payload;
          s.selectedOrganization = a.payload.organization;
        } else {
          s.status.login = statuses.rejected;
          s.messages.login = "Login Failed!";
        }
      },
    },
    rejected: { message: "Incorrect Email or Password!" },
  }),
  register: createAction({
    name: "register",
    actionName: "user/register",
    route: "register",
    method: "POST",
    key: "user",
    version: "v2",
    omitAuth: true,
    pending: { message: "Registering..." },
    fulfilled: { message: "Registered!" },
    rejected: { cb: (s, a) => (s.messages.register = a.payload.message) },
  }),
  createOrganization: createAction({
    name: "createOrganization",
    actionName: "user/createOrganization",
    route: "organizations",
    method: "POST",
    key: "organization",
    version: "v2",
    omitAuth: true,
    pending: { message: "Creating..." },
    fulfilled: { message: "Organization Created!" },
    rejected: { cb: (s, a) => (s.messages.createOrganization = a.payload.message) },
  }),
  passwordToken: createAction({
    name: "passwordToken",
    actionName: "user/passwordToken",
    route: "password/reset",
    version: "v2",
    method: "POST",
    key: "user",
    omitAuth: true,
    pending: { message: "Sending..." },
    fulfilled: { message: "Mail Sent!" },
    rejected: { message: "Error Sending Mail!" },
  }),
  getOrganizations: createAction({
    name: "getOrganizations",
    actionName: "user/getOrganizations",
    route: "organizations",
    method: "GET",
    version: "v2",
    pending: {
      message: "Loading Organizations...",
    },
    fulfilled: {
      message: "Organizations Loaded!",
      cb: (s, a) => {
        s.organizations = a.payload;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getConversations: createAction({
    name: "getConversations",
    actionName: "user/getConversations",
    route: "users/:id/conversations",
    routeParameters: [{ ":id": "user" }],
    method: "GET",
    version: "v2",
    pending: {
      message: "Loading Conversations Data...",
    },
    fulfilled: {
      message: "Conversations Loaded!",
      cb: (s, a) => {
        s.conversations = a.payload;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getConversation: createAction({
    name: "getConversation",
    actionName: "user/getConversation",
    route: "conversations/:user",
    routeParameters: [{ ":user": "user" }],
    method: "GET",
    version: "v2",
    pending: {
      message: "Loading Conversations Data...",
    },
    fulfilled: {
      message: "Conversations Loaded!",
      cb: (s, a) => {
        const index = s.conversations.findIndex((convo) => convo.user._id.$oid === a.payload.user._id.$oid);
        if (index < 0) s.conversations.push(a.payload);
        else s.conversations[index] = a.payload;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  muteConversation: createAction({
    name: "muteConversation",
    actionName: "user/muteConversation",
    route: "conversations/:conversation/mute",
    routeParameters: [{ ":conversation": "conversation" }],
    method: "POST",
    version: "v2",
    pending: { message: "Loading Conversations Data..." },
    fulfilled: {
      message: "Conversations Loaded!",
      cb: (s, a) => {
        const index = s.conversations.findIndex((convo) => convo.user._id.$oid === a.payload.user._id.$oid);
        if (index < 0) s.conversations.push(a.payload);
        else s.conversations[index] = a.payload;
      },
    },
    rejected: { message: "Request Rejected!" },
  }),
  unmuteConversation: createAction({
    name: "unmuteConversation",
    actionName: "user/unmuteConversation",
    route: "conversations/:conversation/mute",
    routeParameters: [{ ":conversation": "conversation" }],
    method: "DELETE",
    version: "v2",
    pending: { message: "Loading Conversations Data..." },
    fulfilled: {
      message: "Conversations Loaded!",
      cb: (s, a) => {
        const index = s.conversations.findIndex((convo) => convo.user._id.$oid === a.payload.user._id.$oid);
        if (index < 0) s.conversations.push(a.payload);
        else s.conversations[index] = a.payload;
      },
    },
    rejected: { message: "Request Rejected!" },
  }),
  updateUser: createAction({
    name: "updateUser",
    actionName: "user/updateUser",
    route: "users/:id",
    version: "v2",
    method: "PUT",
    routeParameters: [{ ":id": "user" }],
    key: "newData",
    pending: {
      message: "Updating User",
    },
    fulfilled: {
      message: "User Updated!",
      cb: (s, a) => {
        if (String(a.payload._id.$oid) === s.user._id.$oid) Object.keys(a.payload).forEach((element) => (s.user[element] = a.payload[element]));
        else s.users[s.users.findIndex((user) => String(user._id.$oid) === String(a.payload._id.$oid))] = a.payload;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  deleteUser: createAction({
    name: "deleteUser",
    actionName: "user/deleteUser",
    route: "users/:id",
    version: "v2",
    method: "DELETE",
    routeParameters: [{ ":id": "user" }],
    pending: {
      message: "Deleting User",
    },
    fulfilled: {
      message: "User Deleted!",
      cb: (s, a) => {
        const removed = s.users.findIndex((user) => user._id.$oid === a.payload._id.$oid);
        s.users.splice(removed, 1);
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  deleteOrganization: createAction({
    name: "deleteOrganization",
    actionName: "user/deleteOrganization",
    route: "organizations/:id",
    version: "v2",
    method: "DELETE",
    routeParameters: [{ ":id": "organization" }],
    pending: {
      message: "Deleting Organization",
    },
    fulfilled: {
      message: "Organization Deleted!",
      cb: (s, a) => {
        const removed = s.organizations.findIndex((organization) => organization.code === a.payload.code);
        s.organizations.splice(removed, 1);
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getUserMoods: createAction({
    name: "getUserMoods",
    actionName: "user/getUserMoods",
    route: "users/:id/moods",
    version: "v2",
    method: "GET",
    routeParameters: [{ ":id": "user" }],
    pending: {
      message: "Loading User Moods Moods...",
    },
    fulfilled: {
      message: "User Moods Loaded!",
      cb: (s, a) => {
        s.currentUserMoods = a.payload;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getNotifications: createAction({
    name: "notifications",
    actionName: "user/notifications",
    route: "organizations/:code/notifications",
    routeParameters: [{ ":code": "code" }],
    method: "GET",
    version: "v2",
    pending: { message: "Loading Notifications..." },
    fulfilled: {
      message: "Notifications Loaded!",
      cb: (s, a) => (s.notifications = a.payload),
    },
    rejected: { message: "Request Rejected!" },
  }),
  createNotification: createAction({
    name: "createNotification",
    actionName: "user/createNotification",
    route: "organizations/:code/notifications",
    routeParameters: [{ ":code": "code" }],
    method: "POST",
    version: "v2",
    key: "notification",
    pending: { message: "Logging in..." },
    fulfilled: {
      cb: (s, a) => s.notifications.push(a.payload),
      message: "Notification Created",
    },
    rejected: { message: "Login Rejected!" },
  }),
  remindNotification: createAction({
    name: "remindNotification",
    actionName: "user/remindNotification",
    route: "organizations/:code/notifications/:id/remind",
    version: "v2",
    method: "POST",
    routeParameters: [{ ":code": "code" }, { ":id": "notification" }],
    pending: {
      message: "Reminding...",
    },
    fulfilled: {
      message: "Notification Reminded!",
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  closeNotification: createAction({
    name: "closeNotification",
    actionName: "user/closeNotification",
    route: "organizations/:code/notifications/:id/expire",
    version: "v2",
    method: "POST",
    routeParameters: [{ ":code": "code" }, { ":id": "notification" }],
    pending: {
      message: "Closing...",
    },
    fulfilled: {
      cb: (s, a) => {
        s.notifications[s.notifications.findIndex((i) => i._id.$oid === a.payload._id.$oid)] = a.payload;
      },

      message: "Notification Closed!",
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  deleteNotification: createAction({
    name: "deleteNotification",
    actionName: "user/deleteNotification",
    route: "notifications/:id",
    version: "v2",
    method: "DELETE",
    routeParameters: [{ ":id": "notification" }],
    pending: {
      message: "Closing...",
    },
    fulfilled: {
      cb: (s, a) => {
        const notificationIndex = s.notifications.findIndex((notification) => notification._id.$oid === a.payload._id.$oid);
        s.notifications.splice(notificationIndex, 1);
      },

      message: "Notification Closed!",
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getLastMoods: createAction({
    name: "lastMoods",
    actionName: "user/lastMoods",
    route: "organizations/:code/moods/last",
    routeParameters: [{ ":code": "code" }],
    method: "GET",
    version: "v2",
    pending: { message: "Fetching Moods..." },
    fulfilled: {
      message: "Latest Moods Loaded!",
      cb: (s, a) => (s.lastMoods = a.payload),
    },
    rejected: { message: "Request Rejected!" },
  }),
  priorities: createAction({
    name: "priority",
    actionName: "user/priority",
    route: "organizations/:code/moods/priority",
    routeParameters: [{ ":code": "code" }],
    method: "GET",
    version: "v2",
    pending: {
      message: "Loading Priority Moods...",
    },
    fulfilled: {
      message: "Priority Moods Loaded!",
      cb: (s, a) => {
        s.priorities = a.payload;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getMoodScore: createAction({
    name: "moodScore",
    actionName: "user/moodScore",
    route: "organizations/:id/score",
    method: "GET",
    version: "v2",
    routeParameters: [{ ":id": "organization" }],
    pending: {
      message: "Loading Priority Moods...",
    },
    fulfilled: {
      message: "Priority Moods Loaded!",
      cb: (s, a) => {
        s.moodScore = a.payload.moodScore;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getReminders: createAction({
    name: "getReminders",
    actionName: "user/getReminders",
    route: "organizations/:code/reminders",
    method: "GET",
    routeParameters: [{ ":code": "code" }],
    version: "v2",
    pending: {
      message: "Loading Priority Moods...",
    },
    fulfilled: {
      message: "Priority Moods Loaded!",
      cb: (s, a) => {
        s.reminders = a.payload;
      },
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  createReminder: createAction({
    name: "createReminder",
    actionName: "user/createReminder",
    route: "organizations/:code/reminders",
    method: "POST",
    version: "v2",
    routeParameters: [{ ":code": "code" }],
    key: "reminder",
    pending: {
      message: "Logging in...",
    },
    fulfilled: {
      cb: (s, a) => {
        s.reminders.push(a.payload);
      },
      message: "Reminder Create",
    },
    rejected: {
      message: "Login Rejected!",
    },
  }),
  removeReminder: createAction({
    name: "removeReminder",
    actionName: "user/removeReminder",
    route: "organizations/:code/reminders/:reminder",
    routeParameters: [{ ":code": "code" }, { ":reminder": "reminder" }],
    version: "v2",
    method: "DELETE",
    pending: { message: "Removing..." },
    fulfilled: {
      cb: (s, a) => (s.reminders = a.payload),
      message: "Reminder Removed",
    },
    rejected: { message: "Rejected!" },
  }),
  archiveUser: createAction({
    name: "archiveUser",
    actionName: "user/archiveUser",
    route: "users/:id",
    routeParameters: [{ ":id": "user" }],
    method: "DELETE",
    pending: {
      message: "Deleting...",
    },
    fulfilled: {
      message: "User Deleted",
    },
    rejected: {
      message: "Login Rejected!",
    },
  }),
  createConversation: createAction({
    name: "createConversation",
    actionName: "user/createConversation",
    route: "conversations",
    method: "POST",
    key: "data",
    version: "v2",
    pending: { message: "Creating..." },
    fulfilled: {
      message: "New Chat Created",
      cb: (s, a) => {
        const index = s.conversations.findIndex((convo) => convo.user._id.$oid === a.payload.user._id.$oid);
        if (index < 0) s.conversations.push(a.payload);
        else s.conversations[index] = a.payload;
      },
    },
    rejected: { message: "Rejected!" },
  }),
  resetPassword: createAction({
    name: "resetPassword",
    actionName: "user/resetPassword",
    route: "password/new",
    method: "POST",
    omitAuth: true,
    key: "password",
    version: "v2",
    pending: { message: "Resetting..." },
    fulfilled: { message: "Password Reset" },
    rejected: { cb: (s, a) => (s.messages.resetPassword = a.payload.message) },
  }),
  createDepartment: createAction({
    name: "createDepartment",
    actionName: "user/createDepartment",
    route: "organizations/:code/departments",
    routeParameters: [{ ":code": "organization" }],
    method: "POST",
    key: "data",
    pending: {
      message: "Creating Department...",
    },
    fulfilled: {
      message: "Department Created",
      cb: (s, a) => {
        s.organization = a.payload;
      },
    },
    rejected: {
      message: "Department Creation Rejected!",
    },
  }),
  deleteDepartment: createAction({
    name: "deleteDepartment",
    actionName: "user/deleteDepartment",
    route: "organizations/:organization/departments/:department",
    routeParameters: [{ ":organization": "organization" }, { ":department": "department" }],
    method: "DELETE",
    key: "data",
    pending: {
      message: "Deleting Department...",
    },
    fulfilled: {
      message: "Department Deleted",
      cb: (s, a) => {
        s.organization = a.payload;
      },
    },
    rejected: {
      message: "Department Deletion Rejected!",
    },
  }),
  registerUser: createAction({
    name: "registerUser",
    actionName: "user/registerUser",
    route: "organizations/:code/users",
    routeParameters: [{ ":code": "code" }],
    version: "v2",
    method: "POST",
    key: "user",
    pending: {
      message: "Creating Users...",
    },
    fulfilled: {
      message: "Users Created",
    },
    rejected: {
      message: "Rejected!",
    },
  }),
  dashboardReport: createAction({
    name: "dashboardReport",
    actionName: "user/dashboardReport",
    route: "organizations/:code/reports/dashboard",
    routeParameters: [{ ":code": "organization" }],
    method: "GET",
    version: "v2",
    pending: {
      message: "Loading Report...",
    },
    fulfilled: {
      message: "Report Fetched",
      cb: (s, a) => {
        s.dashboardReport = a.payload;
      },
    },
    rejected: {
      message: "Login Rejected!",
    },
  }),
  updateOrganization: createAction({
    name: "updateOrganization",
    actionName: "user/updateOrganization",
    route: "organizations/:code",
    routeParameters: [{ ":code": "code" }],
    key: "data",
    method: "PUT",
    version: "v2",
    pending: {
      message: "Loading...",
    },
    fulfilled: {
      message: "Organization Updated",
      cb: (s, a) => (s.organization = a.payload),
    },
    rejected: {
      message: "Update Rejected!",
    },
  }),
  getOrganization: createAction({
    name: "getOrganization",
    actionName: "user/getOrganization",
    route: "organizations/:code",
    routeParameters: [{ ":code": "code" }],
    method: "GET",
    version: "v2",
    pending: {
      message: "Loading...",
    },
    fulfilled: {
      message: "Organization Updated",
      cb: (s, a) => (s.organization = a.payload),
    },
    rejected: {
      message: "Update Rejected!",
    },
  }),
  getOrganizationUsers: createAction({
    name: "getOrganizationUsers",
    actionName: "user/getOrganizationUsers",
    route: "organizations/:code/users",
    routeParameters: [{ ":code": "code" }],
    method: "GET",
    version: "v2",
    pending: {
      message: "Loading...",
    },
    fulfilled: {
      message: "Users Updated",
      cb: (s, a) => (s.users = a.payload),
    },
    rejected: {
      message: "Request Rejected!",
    },
  }),
  getMessage: createAction({
    name: "getMessage",
    actionName: "user/getMessage",
    route: "messages/case/:case",
    routeParameters: [{ ":case": "case" }],
    method: "GET",
    pending: {
      message: "Loading...",
    },
    fulfilled: {
      message: "Messages Fetched",
      cb: (s, a) => (s.currentConversation = a.payload),
    },
    rejected: {
      message: "Update Rejected!",
    },
  }),
  sendMessage: createAction({
    name: "sendMessage",
    actionName: "user/sendMessage",
    route: "convesations/:case",
    version: "v2",
    routeParameters: [{ ":case": "case" }],
    method: "POST",
    key: "users",
    pending: {
      message: "Creating Users...",
    },
    fulfilled: {
      message: "Users Created",
    },
    rejected: {
      message: "Rejected!",
    },
  }),
};

export const [UserActions, UserReducers] = buildFromCreator(UserCreator);
