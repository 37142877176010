import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../redux/UserActions";
import { animated } from "@react-spring/web";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { moodValues } from "../redux/constants";
import { DocumentTextIcon } from "@heroicons/react/solid";
import { ReactComponent as Angry } from "./icons/angry.svg";
import { ReactComponent as Excited } from "./icons/excited.svg";
import { ReactComponent as Happy } from "./icons/happy.svg";
import { ReactComponent as Meh } from "./icons/meh.svg";
import { ReactComponent as Proud } from "./icons/proud.svg";
import { ReactComponent as Sad } from "./icons/sad.svg";
import { ReactComponent as Stressed } from "./icons/stressed.svg";
import { ReactComponent as Well } from "./icons/well.svg";
import { ReactComponent as Worried } from "./icons/worried.svg";
import { moodColors } from "../redux/constants";

export default function UserProfile() {
  const userState = useSelector((state) => state.user);
  const userId = useParams().id;
  const dispatch = useDispatch();
  const user = userState.users.find((orgUser) => orgUser._id.$oid === userId);
  const [loading, setLoading] = useState(true);
  const [moods, setMoods] = useState([]);

  useEffect(() => setMoods(userState.currentUserMoods), [userState.currentUserMoods]);

  useEffect(() => setLoading(userState.status.getUserMoods === "pending"), [userState.status.getUserMoods]);

  useEffect(() => {
    setMoods([]);
    dispatch(UserActions.getUserMoods({ user: userId }));
  }, [userId]);

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-3 grid-rows-3 gap-8 justify-center bg-gray-100 h-screen w-full p-8">
        <div className="col-span-1 flex flex-col md:flex-row xl:flex-col w-full h-full justify-evenly items-center bg-white p-4 rounded-lg shadow-md">
          <UserDataCard user={user} />
        </div>
        <div className="hidden xl:flex flex-col p-4 bg-white rounded-lg col-span-2">
          <UserDataChart moods={moods} loading={loading} />
        </div>
        <div className="col-span-1 row-span-2 flex flex-col w-full h-full bg-white gap-y-4 p-4 overflow-y-auto rounded-lg shadow-md">
          <label className="w-full text-center text-2xl font-bold">Mood History</label>
          <UserMoodHistory moods={moods} />
        </div>
        <div className="col-span-2 row-span-2 hidden xl:flex w-full h-full rounded-lg bg-white">
          <UserMoodPie moods={moods} />
        </div>
      </div>
    </>
  );
}

function UserDataCard({ user }) {
  return (
    <>
      <span className="flex max-w-xs truncate flex-col items-start">
        <label className="font-semibold flex-grow text-xl pointer-events-none">{user.anonymous ? `${user.userName}` : `${user.firstName} ${user.lastName}`}</label>
        {!user.anonymous && <label className="text-sm text-gray-600 pointer-events-none">{String(user.mail)}</label>}
      </span>
      <span className="flex flex-col gap-y-1">
        <span className="flex max-w-xs break-all items-center gap-x-2">
          <label className="text-gray-600">Phone:</label>
          <label className="truncate">{user.phone.number ? `+${user.phone.code || ""} ${user.phone.number}` : "No Phone"}</label>
        </span>
        <span className="flex flex-grow max-w-xs truncate break-all items-center gap-x-2">
          <label className="text-gray-600 ">Departments:</label>
          <label className="flex truncate">{user.departments.join(", ") || "No Department"}</label>
        </span>
        <span className="flex truncate break-all max-w-xs items-center gap-x-2">
          <label className="text-gray-600 ">Location:</label>
          <label className="truncate">{user.locations.join(", ") || "No Location"}</label>
        </span>
      </span>
      <Link to={`/conversations/${user._id.$oid}`} className="p-2 px-4 bg-green-300 rounded font-semibold">
        Contact
      </Link>
    </>
  );
}

function UserDataChart({ moods, loading }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const negative = [];
    const positive = [];
    let counter = 0;

    moods.forEach((mood, idx) => {
      if (idx > 0 && moods[idx - 1]?.mood + 1 !== 0 && Math.sign(moods[idx - 1]?.mood + 1) !== Math.sign(mood?.mood + 1)) {
        positive.push({ x: counter, y: 0 });
        negative.push({ x: counter, y: 0 });
        counter++;
      }

      positive.push({ x: counter, y: mood?.mood + 1 >= 0 ? (mood?.mood + 1) * mood.scale : null, time: new Date(mood.date.$date) });
      negative.push({ x: counter, y: mood?.mood + 1 <= 0 ? (mood?.mood + 1) * mood.scale : null, time: new Date(mood.date.$date) });
      counter++;
    });

    setData([
      { id: "positive", data: positive },
      { id: "negative", data: negative },
    ]);
  }, [moods]);

  return (
    <>
      {" "}
      <span className="flex flex-row w-full h-full">
        {moods.length < 2 ? (
          <label className="m-auto text-2xl font-semibold">{loading ? "Loading..." : "Not Enough Moods Selected"}</label>
        ) : (
          <>
            <span className="h-full w-8 flex flex-col justify-between">
              <Happy className="w-10 h-10 rounded-full fill-current text-green-400" />
              <Meh className="w-10 h-10 rounded-full fill-current text-gray-400" />
              <Angry className="w-10 h-10 rounded-full fill-current text-red-400" />
            </span>

            <span className="h-full w-full flex flex-col justify-between">
              <ResponsiveLine
                margin={{ top: 20, right: 30, bottom: 20, left: 30 }}
                data={data}
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enablePoints={false}
                enableGridX={false}
                colors={["rgb(97, 205, 187)", "rgb(244, 117, 96)"]}
                yScale={{ type: "linear", reverse: false, min: -25, max: 25 }}
                xScale={{ type: "linear", reverse: true }}
                enableArea={true}
                areaOpacity={0.3}
              />
            </span>
          </>
        )}
      </span>
    </>
  );
}

function UserMoodHistory({ moods }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const days = [];

    moods.forEach((mood) => {
      const moodDate = new Date(mood.date.$date).toDateString();
      const index = days.findIndex((day) => day?.day === moodDate);
      if (index >= 0) days[index].moods.push(mood);
      else days.push({ day: moodDate, moods: [mood] });
    });

    setData(days);
  }, [moods]);

  return (
    <>
      {" "}
      {data.map((day) => (
        <span className="flex flex-col gap-y-2">
          <label className="text-center text-xl font-semibold">{new Date(day.day).toLocaleDateString("en-US", { month: "long", day: "numeric" })}</label>
          {day.moods.map((mood) => (
            <MoodCard mood={mood} />
          ))}
        </span>
      ))}
    </>
  );
}

function MoodCard({ mood }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      onClick={() => mood.note != "" && setExpanded(!expanded)}
      className={"flex flex-col justify-start gap-x-8 items-center rounded-lg " + `bg-${moodValues[mood.mood]} ` + (mood.note != "" && "cursor-pointer")}
    >
      <span className="flex p-4 relative w-full overflow-hidden ">
        <span className="flex flex-col gap-y-2 pr-6">
          <span className="flex flex-grow gap-x-4 justify-between items-end">
            <label className="text-2xl font-semibold  text-white">{moodValues[mood.mood]}</label>
            <label className="text-white">{new Date(mood.date.$date).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false })}</label>
          </span>
          <span className={"w-32 h-6 rounded border-2 border-gray-200 relative " + (mood.scale === 5 ? "bg-green-500 " : "bg-white")}>
            <span
              className={
                "absolute h-5 rounded-l " +
                ((mood.scale === 1 && "bg-red-400 w-0") ||
                  (mood.scale === 2 && "bg-Sad w-8") ||
                  (mood.scale === 3 && "bg-yellow-300 w-16") ||
                  (mood.scale === 4 && " bg-green-400 w-24 ") ||
                  (mood.scale === 5 && "w-0 "))
              }
            />
          </span>
        </span>
        {mood.note != "" && <DocumentTextIcon className="flex w-8 h-full self-end text-white " />}
        {moodValues[mood.mood] === "Angry" && <Angry className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Excited" && <Excited className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Happy" && <Happy className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Meh" && <Meh className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Proud" && <Proud className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Sad" && <Sad className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Stressed" && <Stressed className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Well" && <Well className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
        {moodValues[mood.mood] === "Worried" && <Worried className="absolute fill-current text-white transform -right-4 top-2 rotate-45 max-h-16" />}
      </span>
      {expanded && <label className="w-full p-2 text-white bg-black bg-opacity-5 rounded-b-lg text-center text-lg">{String(mood.note)}</label>}
    </div>
  );
}

function UserMoodPie({ moods }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempData = [];
    for (const [key, value] of Object.entries(moodValues)) {
      tempData.push({ label: key, value: moods.filter((mood) => String(mood.mood) === String(key)).length, id: value, color: moodColors[value] });
    }
    setData(tempData.filter((temp) => temp.value > 0));
  }, [moods]);

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      innerRadius={0.5}
      padAngle={2}
      cornerRadius={5}
      colors={{ datum: "data.color" }}
      arcLinkLabelsSkipAngle={1}
      arcLinkLabelsTextColor={{ from: "color" }}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLinkLabelComponent={({ datum, label, style }) => (
        <animated.g transform={style.textPosition} style={{ pointerEvents: "none" }}>
          <text textAnchor="middle" dominantBaseline="central" fill={datum.color} style={{ fontSize: 18, fontWeight: 600 }}>
            {label}
          </text>
        </animated.g>
      )}
      arcLabelsSkipAngle={1}
      arcLabelsTextColor="#000000"
      activeOuterRadiusOffset={15}
      arcLabelsRadiusOffset={0.55}
      arcLabelsComponent={({ datum, label, style }) => (
        <animated.g transform={style.transform} style={{ pointerEvents: "none" }}>
          <text textAnchor="middle" dominantBaseline="central" fill={style.textColor} style={{ fontSize: 18, fontWeight: 600 }}>
            {label}
          </text>
        </animated.g>
      )}
    />
  );
}
