import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions";
import { BellIcon } from "@heroicons/react/solid";
import { BellIcon as BellIconOutline } from "@heroicons/react/outline";

export default function ChatInfo({ chat }) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const user = chat?.user;
  return (
    <div className="flex p-5 gap-x-2 items-center justify-between h-20 border-b">
      <label className="text-lg flex-grow text-center font-medium text-gray-700 pointer-events-none">{user?.anonymous ? `${user?.userName}` : `${user?.firstName} ${user?.lastName}`}</label>

      {!chat?.muted?.includes(userSelector.user._id.$oid) ? (
        <span className="flex flex-col w-16 items-center bg-gray p-1 rounded-md cursor-pointer bg-gray-100 hover:bg-gray-300" onClick={() => dispatch(UserActions.muteConversation({ conversation: chat._id.$oid }))}>
          <BellIconOutline className="h-6 pointer-events-none" />
          <label className="font-semibold text-sm pointer-events-none">Mute</label>
        </span>
      ) : (
        <span className="flex flex-col w-16 items-center bg-gray p-1 rounded-md cursor-pointer bg-gray-100 hover:bg-gray-300" onClick={() => dispatch(UserActions.unmuteConversation({ conversation: chat._id.$oid }))}>
          <BellIcon className="h-6 pointer-events-none" />
          <label className="font-semibold text-sm pointer-events-none">Unmute</label>
        </span>
      )}
    </div>
  );
}
