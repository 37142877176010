import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions";
import ListBox from "../Helper/ListBox";

export default function SubscriptionAndBillingTab() {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [billingAddress, setBillingAddress] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [employeesCount, setEmployeesCount] = useState("");
  const [seats, setSeats] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("");
  const [billingPlan, setBillingPlan] = useState("");

  function updateBilling() {}

  useEffect(() => {
    dispatch(UserActions.getOrganization({ code: userSelector.selectedOrganization }));
  }, []);

  useEffect(() => {}, [userSelector.organization]);

  return (
    <div className="w-full flex-grow flex flex-col gap-y-4">
      <label className="text-xl font-semibold">Manage your subscription settings</label>
      <InputWithLabel name="Billing Address" placeholder={billingAddress} onChange={(e) => setBillingAddress(e)} />
      <InputWithLabel name="Billing Email" placeholder={billingEmail} onChange={(e) => setBillingEmail(e)} />
      <span className="flex flex-col">
        <label className="text-lg">Billing Period</label>
        <ListBox listClassName="w-96 border-gray-300 shadow-lg" current={billingPeriod} handleSelection={(e) => setBillingPeriod(e)} options={["Monthly", "Yearly"]} />
      </span>
      <input type="button" className="bg-green-400 rounded-lg p-2 text-lg w-96 font-semibold cursor-pointer" onClick={(e) => updateBilling()} value="Apply" />
    </div>
  );
}

function InputWithLabel({ name, placeholder, onChange }) {
  return (
    <span className="flex flex-col">
      <label className="text-lg">{name}</label>
      <input type="text" placeholder={placeholder} onChange={(e) => onChange(e.target.value)} className="w-96 rounded-lg shadow-lg border-gray-300" />
    </span>
  );
}
