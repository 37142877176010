const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    role: ["Admin", "Support", "SuperAdmin"],
  },
  {
    path: "/priorities",
    role: ["Admin", "Support", "SuperAdmin"],
    name: "Priorities",
  },
  {
    role: ["Admin", "Support", "SuperAdmin"],
    name: "Users",
    routes: [
      {
        path: "/management/active",
        role: ["Admin", "Support", "SuperAdmin"],
        name: "Active",
      },
      {
        path: "/management/archived",
        role: ["Admin", "SuperAdmin"],
        name: "Archived",
      },
      {
        path: "/management/setup",
        role: ["Admin", "SuperAdmin"],
        name: "Setup",
      },
      {
        path: "/management/deleted",
        role: ["SuperAdmin"],
        name: "Deleted",
      },
    ],
  },
  {
    path: "/organization_settings/departments",
    role: ["Admin", "SuperAdmin"],
    name: "Organisation Settings",
  },
  {
    path: "/notifications/custom",
    role: ["Admin", "Support", "SuperAdmin"],
    name: "Polls",
  },
  {
    path: "/reminders",
    role: ["Admin", "Support", "SuperAdmin"],
    name: "Reminders",
  },
  {
    role: ["Admin", "Support", "SuperAdmin"],
    path: "/conversations",
    name: "Conversations",
  },
  {
    role: ["Admin", "Support", "SuperAdmin"],
    name: "Reports",
    routes: [
      {
        path: "/reports/dashboard",
        role: ["Admin", "Support", "SuperAdmin"],
        name: "Summary",
      },
      // {
      //   path: "/reports/priorities",
      //   name: "Priorities",
      // },
      // {
      //   path: "/reports/Conversations",
      //   name: "Conversations",
      // },
    ],
  },
];

export default routes;
