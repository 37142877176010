import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { repeatSetup } from "../../redux/UserReducer";
import { UserActions } from "../../redux/UserActions";
import { update } from "@react-spring/web";
import ConfirmationDialog from "../ConfirmationDialog";
import ListBox from "../Helper/ListBox";

export default function OrganizationSettingsTab() {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);

  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const [contactPhoneCode, setContactPhoneCode] = useState("");
  const [anonymous, setAnonymous] = useState(userSelector.organization?.anonymous);
  const [targetScore, setTargetScore] = useState(userSelector.organization?.targetScore);
  const [domain, setDomain] = useState("");
  const [removeModalExpanded, setRemoveModalExpanded] = useState(false);

  useEffect(() => dispatch(UserActions.getOrganization({ code: userSelector.selectedOrganization })), []);

  function updateSettings() {
    const updateData = {};

    name !== "" && Object.assign(updateData, { name });
    domain !== "" && Object.assign(updateData, { domain });
    anonymous !== "" && Object.assign(updateData, { anonymous });
    targetScore !== "" && Object.assign(updateData, { targetScore });

    const contact = {};
    contactName !== "" && Object.assign(contact, { name: contactName });
    contactEmail !== "" && Object.assign(contact, { mail: contactEmail });

    const phone = {};
    contactPhoneCode !== "" && Object.assign(phone, { code: contactPhoneCode });
    contactPhoneNumber !== "" && Object.assign(phone, { number: contactPhoneNumber });

    Object.keys(phone).length > 0 && Object.assign(contact, { phone });
    Object.keys(contact).length > 0 && Object.assign(updateData, { contact });

    // console.log(updateData);
    dispatch(UserActions.updateOrganization({ code: userSelector.selectedOrganization, data: JSON.stringify(updateData) }));
  }

  return (
    <div className="w-full flex flex-col gap-y-4">
      <label className="text-xl font-semibold">Manage your organisation settings</label>
      <div className="flex flex-col gap-y-4 w-full lg:w-96">
        <span className="flex flex-col">
          <label className="text-lg">Organisation Name</label>
          <input type="text" placeholder={userSelector.organization.name || ""} onChange={(e) => setName(e.target.value)} className="w-full rounded-lg shadow-lg border-gray-300" />
        </span>
        <span className="flex flex-col">
          <label className="text-lg">Contact Name</label>
          <input type="text" placeholder={userSelector.organization.contact?.name || ""} onChange={(e) => setContactName(e.target.value)} className="w-full rounded-lg shadow-lg border-gray-300" />
        </span>
        <span className="flex flex-col">
          <label className="text-lg">Contact Email</label>
          <input type="text" placeholder={userSelector.organization.contact?.mail || ""} onChange={(e) => setContactEmail(e.target.value)} className="w-full rounded-lg shadow-lg border-gray-300" />
        </span>
        <span className="flex flex-col">
          <label className="text-lg">Contact Phone</label>
          <span className="flex gap-x-6">
            <span className="flex items-center border shadow-lg border-gray-300 rounded-lg">
              <label className="text-xl h-full font-semibold bg-white rounded-l-lg p-1">+</label>
              <input type="text" placeholder={userSelector.organization.contact?.phone?.code || ""} onChange={(e) => setContactPhoneCode(e.target.value)} className="w-20 border-0 rounded-r-lg" />
            </span>
            <input type="text" placeholder={userSelector.organization.contact?.phone?.number || ""} onChange={(e) => setContactPhoneNumber(e.target.value)} className="w-64 rounded-lg shadow-lg border-gray-300" />
          </span>
        </span>
        <span className="flex flex-col">
          <label className="text-lg">Organisation Domain (Ex. wellm8.com)</label>
          <input type="text" placeholder={userSelector.organization?.domain || ""} onChange={(e) => setDomain(e.target.value)} className="w-full rounded-lg shadow-lg border-gray-300" />
        </span>
        <span className="flex justify-between items-center">
          <label className="text-lg">Target Mood Score</label>
          <ListBox listClassName="w-48" options={Array.from({length: 11}, (_, i) => i * 10)} current={60} handleSelection={(sel) => setTargetScore(sel)}/>
          {/* <input type="text" placeholder={userSelector.organization?.targetScore || ""} onChange={(e) => setTargetScore(Math.round(parseInt(e.target.value) / 10) * 10)} className="w-full rounded-lg shadow-lg border-gray-300" /> */}
        </span>
        <span className="flex items-center">
          <label className="text-lg flex-grow">Anonymous Users</label>
          <input type="checkbox" checked={anonymous} onChange={(e) => setAnonymous(e.target.checked)} className="p-2 rounded shadow-lg border-gray-300" />
        </span>
        <input type="button" className="bg-green-400 rounded-lg p-2 text-lg font-semibold cursor-pointer" onClick={(e) => setRemoveModalExpanded(true)} value="Apply" />
        {removeModalExpanded && (
          <ConfirmationDialog
            expanded={removeModalExpanded}
            confirmClass={"bg-green-500 hover:bg-green-300"}
            setExpanded={(e) => setRemoveModalExpanded(e)}
            confirm={() => updateSettings()}
            title="Confirm Organisation Update"
            description="Your organisation settings will be updated."
          />
        )}
        <input type="button" className="bg-blue-400 rounded-lg p-2 text-lg font-semibold cursor-pointer" onClick={(e) => dispatch(repeatSetup("setup"))} value="Repeat Setup" />
      </div>
    </div>
  );
}
