import { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon, XIcon } from "@heroicons/react/solid";
import { mergeClasses } from "../../redux/constants";

export default function MultiSelectListBox({ options, defaultValues, className, handleSelected }) {
  const [selected, setSelected] = useState(defaultValues || []);
  const [expanded, setExpanded] = useState(false);

  function handleSelection(e) {
    if (selected.includes(e)) setSelected(selected.filter((option) => option != e));
    else setSelected([...selected, e]);
  }

  useEffect(() => handleSelected(selected), [selected]);

  return (
    <div tabIndex="0" onBlur={() => setExpanded(false)} className={mergeClasses("relative flex flex-grow ", className ? className : "")}>
      <span className="flex relative items-center h-10 flex-wrap flex-grow overflow-hidden gap-y-2 gap-x-2 border cursor-pointer border-gray-300 bg-gray-100 rounded-md" onClick={() => setExpanded(!expanded)}>
        {selected.length === 0 ? (
          <label className="rounded-md text-center flex-grow px-2 py-1 cursor-pointer">Select...</label>
        ) : (
          selected.map((item) => (
            <span
              className="flex items-center z-10 bg-green-200 rounded-md max-h-24  px-2 py-1 cursor-pointer"
              onClick={(e) => {
                if (expanded) return;
                e.stopPropagation();
                handleSelection(item);
              }}
            >
              <label className="pointer-events-none" key={item}>
                {item}
              </label>
              <XIcon className="h-4 w-4" />
            </span>
          ))
        )}
        {selected.length > 0 ? (
          <label
            className="absolute right-0 z-10 px-2 py-2 bg-red-200 font-semibold cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setSelected([]);
            }}
          >
            Clear
          </label>
        ) : null}
      </span>
      {expanded ? (
        <div className="absolute my-1 bg-gray-100 z-30 shadow-lg border border-gray-200 overflow-y-auto max-h-48 rounded-md w-full flex flex-col items-start">
          {options.map((item) => (
            <div
              key={item}
              className={"w-full py-1 cursor-pointer hover:bg-gray-200 text-left" + (selected.includes(item) ? " border-l-4 border-green-500 bg-gray-300" : "")}
              onClick={() => handleSelection(item)}
            >
              <label className="pl-2">{item}</label>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
