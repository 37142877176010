import React from "react";
import { Link } from "react-router-dom";

export default function UserTitle({ user }) {
  return (
    <Link className="flex flex-col items-center justify-center h-10 gap-x-2 cursor-pointer" to={"/users/" + String(user?._id?.$oid)}>
      <label className="font-semibold text-md pointer-events-none">{user.anonymous ? user.userName : `${user.firstName} ${user.lastName}`}</label>
      {!user.anonymous && <label className="text-xs text-gray-600 truncate break-all pointer-events-none">{String(user.mail)}</label>}
    </Link>
  );
}
