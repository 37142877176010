import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function User({ user, chat, active }) {
  return (
    <>
      <Link key={chat.user._id.$oid} to={`/conversations/${chat.user._id.$oid}`} className={"flex border-gray-100 border-b gap-x-2 items-center hover:bg-gray-100 p-2 " + (active ? "bg-blue-100" : "")}>
        {chat.messages?.length > 0 && chat.supports.find((supp) => supp._id.$oid === user) && !chat.messages[chat.messages.length - 1]?.seenBy?.includes(user) && (
          <span className="w-3 h-3 rounded-full pointer-events-none bg-red-500 "></span>
        )}
        <label className="text-lg font-semibold pointer-events-none">{chat.user.anonymous ? `${chat.user.userName}` : `${chat.user?.firstName} ${chat.user?.lastName}`}</label>
      </Link>
    </>
  );
}
