import { createSlice } from "@reduxjs/toolkit";
import { statuses } from "./constants";

import { UserReducers } from "./UserActions";

export const extraReducers = UserReducers;

const initialState = {
  user: null,
  messages: {
    login: "Login",
    passwordToken: "Send Mail",
    resetPassword: "",
    register: "Register",
    createOrganization: "Create",
  },
  status: {
    login: statuses.idle,
    passwordToken: statuses.idle,
    resetPassword: statuses.idle,
    register: statuses.idle,
  },
  selectedOrganization: "",
  users: [],
  invitationCode: "",
  organizations: [],
  notifications: [],
  organization: {},
  dahboardReport: {},
  lastMoods: [],
  priorities: [],
  reminders: [],
  moodScore: 0,
  conversations: [],
  currentUserMoods: [],
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    repeatSetup: (state, action) => {
      state.organization.status = action.payload;
    },
    newMessageList: (state, action) => {
      const message = JSON.parse(action.payload);
      const index = state.conversations.findIndex((convo) => convo?.user?._id?.$oid === message.user._id.$oid);
      if (index !== -1) {
        if (!message?.messages[0]?.seenBy?.includes(state.user._id.$oid)) new Audio("/sounds/beep.mp3").play();
        const lastMessage = state.conversations[index].messages[state.conversations[index].messages.length - 1];
        if ((lastMessage && lastMessage.body !== message.messages[0].body) || lastMessage.date.$date !== message.messages[0].date.$date) state?.conversations[index]?.messages?.push(message?.messages[0]);
        state.conversations[index].supports = message.supports;
        state.conversations[index].user = message.user;
        state.conversations[index].muted = message.muted;
      }
    },
    changeOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    seeMessages: (state, action) => {
      const conversation = action.payload.conversation;
      const index = state?.conversations?.findIndex((convo) => convo?._id?.$oid === conversation);
      state.conversations[index]?.messages?.forEach(
        (mess, messIndex) => !state.conversations[index].messages[messIndex].seenBy.includes(state.user._id.$oid) && state.conversations[index].messages[messIndex].seenBy.push(state.user._id.$oid)
      );
    },
    addMoodToLatest: (state, action) => {
      const mood = action.payload.mood;
      const index = state.lastMoods?.findIndex((newMood) => newMood.user._id.$oid === mood.user._id.$oid);
      if (index !== -1) state.lastMoods[index] = mood;
    },
  },
  extraReducers: extraReducers,
});

export const { logout, newMessageList, newChatMessage, openChat, repeatSetup, changeOrganization, seeMessages, addMoodToLatest } = UserSlice.actions;

export default UserSlice.reducer;
