import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../redux/UserActions";

export default function UserRegister(props) {
  const userSelector = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState({ firstName: "", lastName: "", mail: "", organization: userSelector.organization?.code });

  const [errorMessage, setErrorMessage] = useState("");

  function closeModal(e) {
    props.setIsOpen(false);
  }

  function regUser() {
    setErrorMessage("");
    if (newUser.mail == "" || !newUser.mail.includes("@")) setErrorMessage("Email not valid!");
    else if (newUser.firstName == "") setErrorMessage("Enter First Name!");
    else if (newUser.lastName == "") setErrorMessage("Enter Last Name!");
    else {
      setErrorMessage("");
      dispatch(UserActions.registerUser({ code: userSelector.selectedOrganization, user: JSON.stringify(newUser) }));
      props.setIsOpen(false);
    }
  }

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto bg-gray-900 bg-opacity-60" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-5/6 md:w-1/2 lg:w-1/3 p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-lg font-medium text-center leading-6 text-gray-900">
                  Register User
                </Dialog.Title>
                <div className="overflow-hidden sm:rounded-md">
                  <div className="grid p-4 lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-3 gap-6 sm:gap-3">
                    <div className="col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        First name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="first-name"
                        autoComplete="given-name"
                        onChange={(e) => setNewUser({ ...newUser, [e.target.name]: e.target.value })}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        id="last-name"
                        autoComplete="family-name"
                        onChange={(e) => setNewUser({ ...newUser, [e.target.name]: e.target.value })}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 md:col-span-6">
                      <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        type="text"
                        name="mail"
                        id="email-address"
                        autoComplete="email"
                        onChange={(e) => setNewUser({ ...newUser, [e.target.name]: e.target.value.toLowerCase() })}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4 text-right">
                    <button
                      type="submit"
                      className="inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                      onClick={() => regUser()}
                    >
                      Register
                    </button>
                    <button
                      type="cancel"
                      className="inline-flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
