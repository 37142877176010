import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "./redux/UserActions";
import { newMessageList, addMoodToLatest } from "./redux/UserReducer";
import { io } from "socket.io-client";

import AccessibleNavigationAnnouncer from "./Components/AccessibleNavigationAnnouncer";
import ActiveUsers from "./Components/ActiveUsers";
import Login from "./Components/Login";
import CreateAccount from "./Components/CreateAccount";
import UserProfile from "./Components/UserProfile";
import Priorities from "./Components/Priorities";
import SideBar from "./Components/SideBar";
import OrganizationSettings from "./Components/OrganizationSettings";
import ResetPassword from "./Components/ResetPassword";
import Dashboard from "./Components/Dashboard";
import Conversations from "./Components/Conversation/Conversations";
import Organizations from "./Components/Organizations";
import Reminders from "./Components/Reminders";
import DashboardReport from "./Components/Reports/Dashboard";
import SetupOrganization from "./Components/SetupOrganization";
import Notifications from "./Components/Notifications";
import ForgotPassword from "./Components/ForgotPassword";
import Download from "./Components/Download";
import CreateOrganization from "./Components/CreateOrganization";

export const socket = io("https://api.wellm8.com");

const publicRoutes = [
  { pathname: "/login", path: "/login", component: Login },
  { pathname: "/reset_password", path: "/reset_password", component: ResetPassword },
  { pathname: "/forgot_password", path: "/forgot_password", component: ForgotPassword },
  { pathname: "/create_account", path: "/create_account", component: CreateAccount },
  { pathname: "/create_organization", path: "/create_organization", component: CreateOrganization },
  { pathname: "/download", path: "/download", component: Download },
];

const privateRoutes = [
  { pathname: "/dashboard", path: "/dashboard", component: Dashboard },
  { pathname: "/management/", path: "/management/:status", component: ActiveUsers },
  // { pathname: "/reset_password", path: "/reset_password", component: ResetPassword },
  // { pathname: "/login", path: "/login", component: Login },
  // { pathname: "/create_account", path: "/create_account", component: CreateAccount },
  { pathname: "/priorities", path: "/priorities", component: Priorities },
  { pathname: "/organization_settings/", path: "/organization_settings/:tab", component: OrganizationSettings },
  { pathname: "/organizations", path: "/organizations", component: Organizations },
  { pathname: "/notifications/custom", path: "/notifications/custom", component: Notifications },
  { pathname: "/reminders", path: "/reminders", component: Reminders },
  { pathname: "/conversations", path: "/conversations/:user?", component: Conversations },
  { pathname: "/reports/dashboard", path: "/reports/dashboard", component: DashboardReport },
  { pathname: "/users", path: "/users/:id", component: UserProfile },
];

function Routes() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [initialize, setInitialize] = useState(true);
  const path = useLocation().pathname;
  const [showSetup, setShowSetup] = useState(false);

  useEffect(() => {
    if (user.user?._id?.$oid && initialize) {
      socket.emit("join_room", { _id: String(user.user._id.$oid), room: String(user?.user?._id.$oid) });
      socket.emit("join_room", { _id: String(user.user._id.$oid), room: String(user?.selectedOrganization) });
      socket.on("new_message", (data) => dispatch(newMessageList(data)));
      socket.on("new_mood", (data) => dispatch(addMoodToLatest(data)));
      dispatch(UserActions.getConversations({ user: user.user._id.$oid }));
      setInitialize(false);
    }
    setInitialize(user?.user?._id?.$oid ? false : true);
  }, [user.user]);

  useEffect(() => setShowSetup(user.organization.status === "setup"), [user.organization.status]);

  useEffect(() => {
    if (user.user?._id?.$oid) {
      dispatch(UserActions.getOrganization({ code: user.selectedOrganization }));
      dispatch(UserActions.getOrganizationUsers({ code: user.selectedOrganization }));
      dispatch(UserActions.getLastMoods({ code: user.selectedOrganization }));
      socket.emit("join_room", { _id: String(user.user._id.$oid), room: String(user.selectedOrganization) });
    }
  }, [user.selectedOrganization]);

  return user?.user === null ? (
    <>
      {publicRoutes.map((route) => (
        <Route exact path={route.path} component={route.component} />
      ))}
      {publicRoutes.filter((r) => path.includes(r.pathname)).length === 0 && <Redirect to="/login" />}
    </>
  ) : (
    <>
      <SideBar />
      {privateRoutes.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      {privateRoutes.filter((r) => path.includes(r.pathname)).length === 0 && (user.isNotSetup ? <Redirect to="/setup" /> : <Redirect to="/dashboard" />)}
      {showSetup && <SetupOrganization showSetup={(e) => setShowSetup(e)} />}
    </>
  );
}

export default function App() {
  return (
    <Router>
      <div id="root" className="flex flex-col lg:flex-row h-screen">
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Routes />
        </Switch>
      </div>
    </Router>
  );
}
